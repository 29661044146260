const Bnb = (props) => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="16.5" fill="url(#paint0_radial_2561_112836)"/>
    <path d="M20.3606 22.5161L24.4 18.5803L28.4414 22.5181L30.7918 20.2279L24.4 14L18.0102 20.2259L20.3606 22.5161ZM14 24.1333L16.3504 21.8432L18.7008 24.1333L16.3504 26.4235L14 24.1333ZM20.3606 25.7506L24.4 29.6864L28.4414 25.7486L30.7918 28.0377L24.4 34.2667L18.0102 28.0407L18.0071 28.0377L20.3606 25.7506ZM30.0992 24.1333L32.4496 21.8432L34.8 24.1333L32.4496 26.4235L30.0992 24.1333ZM26.7837 24.1313H26.7858V24.1333L24.4 26.4579L22.0174 24.1374L22.0132 24.1333L22.0174 24.1303L22.4344 23.7229L22.6372 23.5253L24.4 21.8087L26.7847 24.1323L26.7837 24.1313Z" fill="#F3BA2F"/>
    <g filter="url(#filter0_f_2561_112836)">
      <circle cx="24.5" cy="24.5" r="20" stroke="#F3BA2F"/>
    </g>
    <circle cx="24.5" cy="24.5" r="20" stroke="#F3BA2F"/>
    <defs>
      <filter id="filter0_f_2561_112836" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2561_112836"/>
      </filter>
      <radialGradient id="paint0_radial_2561_112836" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 24.5) rotate(90) scale(25)">
        <stop stopColor="#05001C"/>
        <stop offset="1" stopColor="#434037"/>
      </radialGradient>
    </defs>
  </svg>
);

export default Bnb;
