// core
import {useTranslation} from "react-i18next";

// components
import Globe from "../../../components/icons/Globe";
import Twitter from "../../../components/icons/Twitter";
import Telegram from "../../../components/icons/Telegram";
import Medium from "../../../components/icons/Medium";
import YouTube from "../../../components/icons/YouTube";
import Question from "../../../components/icons/Question";
import Pdf from "../../../components/icons/Pdf";

// styles
import './index.scss'

const HomeNav = ({faqElRef}) => {
  const {t} = useTranslation();

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const scrollToFaq = () => {
    if (faqElRef) {
      window.scrollTo({top: (faqElRef.current.offsetTop), behavior: 'smooth'});
    }
  }

  return (
    <div className="home-nav">
      <div className="home-nav__content">
        <ul>
          <li>
          <span onClick={() => scrollTop()}>
            <Globe/>
          </span>
            <div className="home-nav__tooltip">{t('nav.home')}</div>
          </li>
          <li>
            <a href={'https://twitter.com/MoonWinGame'} target="_blank" rel="noreferrer">
              <Twitter/>
            </a>
            <div className="home-nav__tooltip">{t('nav.twitter')}</div>
          </li>
          <li>
            <a href={'https://t.me/moonwingame'} target="_blank" rel="noreferrer">
              <Telegram/>
            </a>
            <div className="home-nav__tooltip">{t('nav.telegram')}</div>
          </li>
          <li>
            <a href={'https://www.youtube.com/channel/UCXmMLkk8PI9P5kwL6r-yl_Q'} target="_blank" rel="noreferrer">
              <YouTube/>
            </a>
            <div className="home-nav__tooltip">{t('nav.youtube')}</div>
          </li>
          <li>
            <a href={'https://medium.com/@moonwinadv'} target="_blank" rel="noreferrer">
              <Medium/>
            </a>
            <div className="home-nav__tooltip">{t('nav.medium')}</div>
          </li>
        </ul>

        <ul>
          <li>
          <span onClick={() => scrollToFaq()}>
            <Question/>
          </span>
            <div className="home-nav__tooltip">{t('nav.faq')}</div>
          </li>
          <li>
            <a href={`${process.env.PUBLIC_URL}/whitepaper.pdf`} target="_blank" rel="noreferrer">
              <Pdf/>
            </a>
            <div className="home-nav__tooltip">{t('nav.whitepaper')}</div>
          </li>
        </ul>

      </div>
    </div>
  );
};

export default HomeNav;
