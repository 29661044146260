// core
import React from 'react';

// components
import Header from '../../atoms/Header';
import Footer from '../../atoms/Footer';

// style
import './index.scss'

const MainLayout = ({children}) => {
  return (
    <div className='app-main'>
      <div className='app-main__top'><Header/></div>
      <div className='app-main__content'>{children}</div>
      <div className='app-main__bottom'><Footer/></div>
    </div>
  );
};

export default MainLayout;
