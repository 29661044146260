const ListPoint = (props) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3362_125299)">
      <circle cx="28.5938" cy="24.2913" r="8" fill="url(#paint0_linear_3362_125299)"/>
      <circle cx="28.5938" cy="24.2913" r="7.5" stroke="url(#paint1_linear_3362_125299)"/>
    </g>
    <g filter="url(#filter1_d_3362_125299)">
      <circle cx="28.5937" cy="24.2912" r="2.66667" fill="#1ECACA"/>
    </g>
    <defs>
      <filter id="filter0_d_3362_125299" x="0.59375" y="0.29126" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3362_125299"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3362_125299" result="shape"/>
      </filter>
      <filter id="filter1_d_3362_125299" x="5.927" y="5.62451" width="45.3333" height="45.3333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3362_125299"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3362_125299" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_3362_125299" x1="21.2604" y1="23.9579" x2="36.2604" y2="23.9579" gradientUnits="userSpaceOnUse">
        <stop stopColor="#36F8F8"/>
        <stop offset="1" stopColor="#39F8F8"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3362_125299" x1="28.5938" y1="16.2913" x2="28.5938" y2="37.6246" gradientUnits="userSpaceOnUse">
        <stop offset="0.326305" stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>

);

export default ListPoint;


