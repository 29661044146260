export const goToPlatformLink = (path) => {
  let url = path ? `${process.env.REACT_APP_API_CUSTOM_LINK_REDIRECT}/${path}` : process.env.REACT_APP_API_CUSTOM_LINK_REDIRECT;
  let storageValue = sessionStorage.getItem('customLinkAddress');

  url = storageValue && storageValue.length ? `${url}?infl=${storageValue}` : url

  window.location.href = url;
}

export const clearStoragePlatformLink = () => {
  let storageValue = sessionStorage.getItem('customLinkAddress');
  if (storageValue) {
    sessionStorage.removeItem('customLinkAddress');
  }
}
