// core
import {useTranslation} from "react-i18next";

// components
import GeneralBGFeature from "../../../components/images/GeneralBGFeature";
import Feature1 from "../../../components/icons/Feature1";
import Feature2 from "../../../components/icons/Feature2";
import Feature3 from "../../../components/icons/Feature3";
import Feature4 from "../../../components/icons/Feature4";

import FeatureGradient1 from "./featureGradient1";
import FeatureGradient2 from "./featureGradient2";
import FeatureGradient3 from "./featureGradient3";
import FeatureGradient4 from "./featureGradient4";

// styles
import './index.scss'
import React from "react";

const HomeFeatures = () => {
  const {t} = useTranslation();
  return (
    <div className="home-features">
      <div className="home-features__bg">
        <GeneralBGFeature/>
      </div>
      <div className="app__wrapper">
        <div className="home-features__content">
          <div className="home-features__title">
            {t('modules.home.features.title')}
          </div>
          <div className="home-features__grid">
            <div className="home-features__card">
              <div className="home-features__card-bg"/>
              <div className="home-features__card-img"><Feature1/></div>
              <div className="home-features__card-gradient">
                <FeatureGradient1/>
              </div>
              <div className="home-features__card-text"
                   dangerouslySetInnerHTML={{__html: t('modules.home.features.list.item1')}}
              />
            </div>
            <div className="home-features__card">
              <div className="home-features__card-bg"/>
              <div className="home-features__card-img"><Feature2/></div>
              <div className="home-features__card-gradient">
                <FeatureGradient2/>
              </div>
              <div className="home-features__card-text"
                   dangerouslySetInnerHTML={{__html: t('modules.home.features.list.item2')}}
              />

            </div>
            <div className="home-features__card">
              <div className="home-features__card-bg"/>
              <div className="home-features__card-img"><Feature3/></div>
              <div className="home-features__card-gradient">
                <FeatureGradient3/>
              </div>
              <div className="home-features__card-text"
                   dangerouslySetInnerHTML={{__html: t('modules.home.features.list.item3')}}
              />
            </div>

            <div className="home-features__card">
              <div className="home-features__card-bg"/>
              <div className="home-features__card-img"><Feature4/></div>
              <div className="home-features__card-gradient">
                <FeatureGradient4/>
              </div>
              <div className="home-features__card-text"
                   dangerouslySetInnerHTML={{__html: t('modules.home.features.list.item4')}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFeatures;
