// core
import {useWindowSize} from 'react-use';
import {useTranslation} from "react-i18next";

// utils
import {goToPlatformLink} from "../../../utils/common";

// components
import AppButton from "../../../components/atoms/Button";
import Logo from "../../../components/images/Logo";
import RisePoint from "../../../components/images/RisePoint";

// styles
import './index.scss'

const HomeRise = () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();

  return (
    <div className="home-rise">
      <div className="home-rise__title">
        {t('modules.home.rise.text')}
      </div>
      <div className="home-rise__grid">
        {width > 768 ? (
          <>
            <div className="home-rise__row home-rise__row--1">
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item1')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item2')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item3')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item4')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
            </div>
            <div className="home-rise__line"/>
            <div className="home-rise__row home-rise__row--2">
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item5')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item6')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item7')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="home-rise__row home-rise__row--1">
              <div className="home-rise__line"/>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item1')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item5')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item2')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item6')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item3')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item7')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
            </div>

            <div className="home-rise__row home-rise__row--2">

              <div className="home-rise__card">
                <div className="home-rise__card-content">
                  <div className="home-rise__card-text">
                    {t('modules.home.rise.list.item4')}
                  </div>
                  <div className="home-rise__point"><RisePoint/></div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
      <div className="home-rise__actions">
        <AppButton onClick={() => goToPlatformLink()}
                   className="home-rise__btn"
                   size={'big'}>
          {t('actions.playNow')}
        </AppButton>
        <div className="home-rise__actions-logo">
          <Logo/>
        </div>
      </div>
    </div>
  );
};

export default HomeRise;
