// core
import {useTranslation} from "react-i18next";

// components
import How1 from "../../../components/images/inflencer/How1";
import How2 from "../../../components/images/inflencer/How2";
import How3 from "../../../components/images/inflencer/How3";
import How4 from "../../../components/images/inflencer/How4";

// styles
import './index.scss'


const InfluencerHow = () => {

  const {t} = useTranslation();
  return (
    <div className="influencer-how">
      <div className="influencer-how__content">
        <h2 className="influencer-how__title"
            dangerouslySetInnerHTML={{__html: t('modules.influencer.how.title')}}/>
        <div className="influencer-how__grid">
          <div className="influencer-how__grid-item">
            <div className="influencer-how__card">
              <div className="influencer-how__card-info">
                <div className="influencer-how__card-num">
                  1
                </div>
                <div className="influencer-how__card-image">
                  <How1/>
                </div>
              </div>

              <div className="influencer-how__card-text">
                {t('modules.influencer.how.list.item1')}
              </div>
            </div>
          </div>

          <div className="influencer-how__grid-item">
            <div className="influencer-how__card">
              <div className="influencer-how__card-info">
                <div className="influencer-how__card-num">
                  2
                </div>
                <div className="influencer-how__card-image">
                  <How2/>
                </div>
              </div>
              <div className="influencer-how__card-text">
                {t('modules.influencer.how.list.item2')}
              </div>
            </div>
          </div>

          <div className="influencer-how__grid-item">
            <div className="influencer-how__card">
              <div className="influencer-how__card-info">
                <div className="influencer-how__card-num">
                  3
                </div>
                <div className="influencer-how__card-image">
                  <How3/>
                </div>
              </div>
              <div className="influencer-how__card-text">
                {t('modules.influencer.how.list.item3')}
              </div>
            </div>
          </div>

          <div className="influencer-how__grid-item">
            <div className="influencer-how__card">
              <div className="influencer-how__card-info">
                <div className="influencer-how__card-num">
                  4
                </div>
                <div className="influencer-how__card-image">
                  <How4/>
                </div>
              </div>
              <div className="influencer-how__card-text">
                {t('modules.influencer.how.list.item4')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerHow;
