const HeadBannerBG1 = (props) => (
  <svg width="645" height="757" viewBox="0 0 645 757" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_2775_117766)">
      <path
        d="M210.651 309.704C179.916 422.758 221.017 532.354 302.454 554.494C383.89 576.633 332.463 444.108 363.198 331.053C393.933 217.999 495.192 167.226 413.755 145.087C332.319 122.947 241.386 196.649 210.651 309.704Z"
        fill="#A34EFC" fillOpacity="0.5"/>
    </g>
    <defs>
      <filter id="filter0_f_2775_117766" x="0.456055" y="-58.8407" width="644.302" height="815.779"
              filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_2775_117766"/>
      </filter>
    </defs>
  </svg>

);

export default HeadBannerBG1;


