// core
import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

// utils
import ScrollToTop from './utils/scroll-to-top';
import {clearStoragePlatformLink} from "./utils/common";

// layouts
import MainLayout from './components/layouts/MainLayout';

// modules
import Home from './modules/Home';
import Influencer from './modules/Influencer';

function App() {
  useEffect(() => () => {clearStoragePlatformLink()}, []);

  return (
    <div className="app">
      <ScrollToTop/>
      <Routes>

        <Route path="/influencer" element={<MainLayout><Influencer/></MainLayout>}/>
        <Route path="/:customLink" element={<MainLayout><Home/></MainLayout>}/>
        <Route path="/" element={<MainLayout><Home/></MainLayout>}/>
        <Route path="*" element={<MainLayout><Home/></MainLayout>}/>
      </Routes>
    </div>
  );
}

export default App;
