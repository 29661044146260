// core
import {useTranslation} from "react-i18next";

// components
import Partner from './../../../assets/images/partner.png'
import PartnerRocket from "../../../components/images/inflencer/PartnerRocket";

// styles
import './index.scss'
import AppButton from "../../../components/atoms/Button";

const InfluencerPartner = ({onClick}) => {
  const {t} = useTranslation();
  return (
    <div className="influencer-partner">
      <div className="influencer-partner__grid">
        <div className="influencer-partner__bg">
          <svg width="1218" height="672" viewBox="0 0 1218 672" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <g filter="url(#filter0_f_5315_141349)">
                <path d="M620.556 549.694C915.676 550.183 1143.34 439.593 1129.07 302.684C1127.91 291.537 1125.18 280.559 1120.98 269.797C1142.16 296.783 1154.09 325.324 1154.92 354.503C1158.82 491.151 917.864 590.061 616.735 575.426C352.988 562.607 130.557 466.934 76.7794 351.565C138.909 464.274 362.84 549.267 620.556 549.694Z" fill="url(#paint0_linear_5315_141349)"/>
              </g>
              <g filter="url(#filter1_f_5315_141349)">
                <path d="M649.274 500.19C935.922 471.842 1134.14 338.054 1092.01 201.366C1088.58 190.237 1083.66 179.432 1077.37 168.987C1103.51 194.135 1120.98 221.755 1127.82 251.103C1159.81 388.542 946.28 511.848 650.877 526.515C392.145 539.361 156.422 464.606 80.3862 353.503C163.983 461.107 398.957 524.945 649.274 500.19Z" fill="url(#paint1_linear_5315_141349)"/>
              </g>
              <g filter="url(#filter2_f_5315_141349)">
                <path d="M674.892 456.462C951.274 412.777 1126.46 291.881 1066.17 186.434C1061.27 177.849 1054.95 169.63 1047.33 161.8C1076.41 179.842 1097.35 200.487 1108.13 223.291C1158.61 330.083 966.988 443.785 680.134 477.252C428.889 506.564 187.919 464.603 98.0473 381.98C194.824 461.265 433.539 494.611 674.892 456.462Z" fill="#FF0000" fillOpacity="0.2"/>
              </g>
            </g>
            <defs>
              <filter id="filter0_f_5315_141349" x="26.7793" y="219.797" width="1178.19" height="407.065" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_5315_141349"/>
              </filter>
              <filter id="filter1_f_5315_141349" x="30.3862" y="118.987" width="1150.6" height="458.961" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_5315_141349"/>
              </filter>
              <filter id="filter2_f_5315_141349" x="48.0474" y="111.8" width="1118.15" height="424.779" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_5315_141349"/>
              </filter>
              <linearGradient id="paint0_linear_5315_141349" x1="644.492" y1="189.524" x2="727.316" y2="574.749" gradientUnits="userSpaceOnUse">
                <stop stopColor="#24F2F2"/>
                <stop offset="1" stopColor="#2D8BFE"/>
              </linearGradient>
              <linearGradient id="paint1_linear_5315_141349" x1="1016.43" y1="526.287" x2="358.913" y2="-56.3704" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F2AD7D"/>
                <stop offset="1" stopColor="#A34EFC"/>
              </linearGradient>
            </defs>
          </svg>

        </div>
        <div className="influencer-partner__content">
          <div className="influencer-partner__box">
            <div className="influencer-partner__box-bg"></div>
            <h2 className="influencer-partner__title">
              {t('modules.influencer.partner.title')}
            </h2>
            <div className="influencer-partner__text">
              {t('modules.influencer.partner.text')}
            </div>
          </div>

          <div className="influencer-partner__info">
            <PartnerRocket/>
            <div className="influencer-partner__info-text">
              {t('modules.influencer.partner.text')}
            </div>
            <div className="influencer-partner__actions">
              <AppButton
                onClick={onClick}
                className="influencer-partner__btn app-button--fill-2">
                {t('actions.becomePartner')}
              </AppButton>
            </div>
          </div>
        </div>
        <div className="influencer-partner__image">
          <img src={Partner} alt=''/>
        </div>
      </div>
    </div>
  );
};

export default InfluencerPartner;
