const Aave = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2755_121795)">
      <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="url(#paint0_linear_2755_121795)"/>
      <path d="M23.0021 22.3752L17.5918 9.29482C17.2866 8.61853 16.833 8.28864 16.2351 8.28864H15.7567C15.1588 8.28864 14.7052 8.61853 14.4 9.29482L12.0454 14.9938H10.2639C9.73197 14.9979 9.29898 15.4268 9.29486 15.9629V15.9752C9.29898 16.5072 9.73197 16.9402 10.2639 16.9443H11.2206L8.97321 22.3752C8.93197 22.4948 8.90723 22.6185 8.90723 22.7464C8.90723 23.0515 9.00207 23.2907 9.17114 23.4763C9.34022 23.6618 9.58352 23.7526 9.88867 23.7526C10.0907 23.7484 10.2845 23.6866 10.4454 23.567C10.6186 23.4474 10.7382 23.2742 10.833 23.0763L13.3072 16.9402H15.0227C15.5546 16.9361 15.9876 16.5072 15.9918 15.9711V15.9464C15.9876 15.4144 15.5546 14.9814 15.0227 14.9773H14.1072L15.9959 10.2721L21.1423 23.0721C21.2371 23.2701 21.3567 23.4433 21.5299 23.5629C21.6907 23.6825 21.8887 23.7443 22.0866 23.7484C22.3918 23.7484 22.6309 23.6577 22.8041 23.4721C22.9773 23.2866 23.0681 23.0474 23.0681 22.7422C23.0722 22.6185 23.0516 22.4907 23.0021 22.3752Z" fill="white"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_2755_121795" x1="27.9294" y1="5.96233" x2="4.12137" y2="25.9943" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B6509E"/>
        <stop offset="1" stopColor="#2EBAC6"/>
      </linearGradient>
      <clipPath id="clip0_2755_121795">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default Aave;
