// core
import React from "react";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "react-use";

// components
import WeOffer from './../../../assets/images/we-offer.png'

// styles
import './index.scss'

const InfluencerGet= () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();

  return (
    <div className="influencer-offer">
      <div className="influencer-offer__grid">

        <div className="influencer-offer__content">
          {width > 1024 && (
            <h2 className="influencer-offer__title"
                dangerouslySetInnerHTML={{__html: t('modules.influencer.offer.title')}}/>
          )}

          <ul className="influencer-offer__list">
            <li>{t('modules.influencer.offer.list.item1')}</li>
            <li>{t('modules.influencer.offer.list.item2')}</li>
            <li>{t('modules.influencer.offer.list.item3')}</li>
            <li>{t('modules.influencer.offer.list.item4')}</li>
          </ul>
        </div>

        <div className="influencer-offer__image">
          <img src={WeOffer} alt=''/>
        </div>
        {width <= 1024 && (
          <h2 className="influencer-offer__title"
              dangerouslySetInnerHTML={{__html: t('modules.influencer.offer.title')}}/>
        )}
      </div>
    </div>
  );
};

export default InfluencerGet;
