const StakingCard1 = (props) => (
  <svg width="53" height="68" viewBox="0 0 53 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2775_117728)">
      <path d="M27.5 1C12.5883 1 0.5 13.0883 0.5 28C0.5 42.9117 12.5883 55 27.5 55V1Z" stroke="#2D8BFE"/>
      <path d="M27.5 10.72L16.7 28.27L27.5 35.02V10.72Z" stroke="#2D8BFE" strokeLinejoin="round"/>
      <path d="M27.5 45.82V37.045L16.7 30.97L27.5 45.82Z" stroke="#2D8BFE" strokeLinejoin="round"/>
      <path d="M16.7 28.27L27.5 35.02V24.22L16.7 28.27Z" stroke="#2D8BFE" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5 47C37.4411 47 45.5 38.9411 45.5 29C45.5 19.0589 37.4411 11 27.5 11V12C36.8888 12 44.5 19.6112 44.5 29C44.5 38.3888 36.8888 46 27.5 46V47Z" fill="#18F3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5 41C34.1274 41 39.5 35.6274 39.5 29C39.5 22.3726 34.1274 17 27.5 17V18C33.5751 18 38.5 22.9249 38.5 29C38.5 35.0751 33.5751 40 27.5 40V41Z" fill="#18F3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.5344 6.93726L27.7622 11.4065L28.612 11.9336L31.052 7.99997H38.5V6.99997H30.6355L30.5344 6.93726Z" fill="#18F3FF"/>
      <circle cx="41" cy="7.5" r="3" stroke="#18F3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.2722 50.9963L27.5 46.5271L28.3498 46L30.7898 49.9336H38.2378V50.9336H30.3733L30.2722 50.9963Z" fill="#18F3FF"/>
      <circle r="3" transform="matrix(1 0 0 -1 40.7378 50.4336)" stroke="#18F3FF"/>
    </g>
    <defs>
      <filter id="filter0_d_2775_117728" x="0" y="0.5" width="52.5" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="5" dy="10"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2775_117728"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2775_117728" result="shape"/>
      </filter>
    </defs>
  </svg>

);

export default StakingCard1;


