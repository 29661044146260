// core
import React from 'react';

// components
import CircleMinus from './../../icons/CircleMinus'
import CirclePlus from './../../icons/CirclePlus'


// styles
import './index.scss'

const Expander = ({title, active, children, onClick}) => {
  return (
    <div className={"expander" + (active ? " expander--active" : '')}>
      <button className='expander__head' onClick={() => onClick(!active)}>
        <div className='expander__title'>{title}</div>
        <div className='expander__arrow'>
          {active ? <CircleMinus/> : <CirclePlus/> }
        </div>
      </button>
      {active && (
        <div className="expander__body">
          {children}
        </div>
      )}
    </div>
  );
};

export default Expander;
