// core
import React from 'react';
import {useWindowSize} from 'react-use';
import {useTranslation} from "react-i18next";

// utils
import {goToPlatformLink} from "../../../utils/common";

// components
import CardsBg1 from "../../../components/images/CardsBg1";
import CardsBg2 from "../../../components/images/CardsBg2";
import CardsBg3 from "../../../components/images/CardsBg3";
import CardsBgMob from "../../../components/images/CardsBgMob";
import AppButton from "../../../components/atoms/Button";
import card1 from './../../../assets/images/card1.png'
import card2 from './../../../assets/images/card2.png'


// styles
import './index.scss'

const HomeCards= () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();

  return (
    <div className="home-cards">
      <div className="home-cards__bg">
        <CardsBg1/>
      </div>
      <div className="home-cards__content">
        <div className="home-cards__content-bg">
          {width > 640 && (
            <>
              <CardsBg2/>
              <CardsBg3/>
            </>
          )}
          {width <= 390 && (
            <>
              <CardsBgMob/>
              <CardsBgMob/>
            </>
          )}

        </div>
        <div className="home-cards__grid">
          <div className="home-cards__item">
          <div className="home-cards__card">
            <div className="home-cards__item-image">
              <img src={card1} align="moonwin 1" alt=''/>
            </div>
            <div className="home-cards__item-title">
              {t('modules.home.cards.card1.title')}
            </div>
            <div className="home-cards__item-text">
              {t('modules.home.cards.card1.text')}
            </div>
            <div className="home-cards__item-actions">
              <AppButton onClick={() => goToPlatformLink()}
                         size={'small'}
                         outline
                         className="home-cards__item-btn">
                {t('actions.tryNow')}
              </AppButton>
            </div>
          </div>
          </div>
          <div className="home-cards__item">
          <div className="home-cards__card">
            <div className="home-cards__item-image">
              <img src={card2} align="moonwin 2" alt=''/>
            </div>
            <div className="home-cards__item-title">
              {t('modules.home.cards.card2.title')}
            </div>
            <div className="home-cards__item-text">
              {t('modules.home.cards.card2.text')}
            </div>
            <div className="home-cards__item-actions">
              <AppButton onClick={() => goToPlatformLink()}
                         size={'small'}
                         outline
                         disabled
                         className="home-cards__item-btn">
                {t('actions.register')}
              </AppButton>
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default HomeCards;
