const Eth = (props) => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="16.5" fill="url(#paint0_radial_2561_112836)"/>
    <path d="M24.8 14V28.85L31.6 24.725L24.8 14Z" fill="#E6E6E6"/>
    <path d="M24.8 14L18 24.725L24.8 28.85V14Z" fill="white"/>
    <path d="M24.8 30.0875L24.8 35.45L31.6 26.375L24.8 30.0875Z" fill="#E6E6E6"/>
    <path d="M24.8 35.45V30.0875L18 26.375L24.8 35.45Z" fill="white"/>
    <path opacity="0.1" d="M24.8 28.85L31.6 24.725L24.8 22.25V28.85Z" fill="#141414"/>
    <path opacity="0.1" d="M18 24.725L24.8 28.85V22.25L18 24.725Z" fill="#393939"/>
    <g filter="url(#filter0_f_2561_112836)">
      <circle cx="24.5" cy="24.5" r="20" stroke="#98C6FF"/>
    </g>
    <circle cx="24.5" cy="24.5" r="20" stroke="#3F82D3"/>
    <defs>
      <filter id="filter0_f_2561_112836" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2561_112836"/>
      </filter>
      <radialGradient id="paint0_radial_2561_112836" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 24.5) rotate(90) scale(25)">
        <stop stopColor="#05001C"/>
        <stop offset="1" stopColor="#3F82D3"/>
      </radialGradient>
    </defs>
  </svg>


);

export default Eth;
