const MediumBtn = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.1407 6C11.256 6 13.7812 8.54286 13.7812 11.6794C13.7812 14.816 11.2558 17.3586 8.1407 17.3586C5.02561 17.3586 2.5 14.816 2.5 11.6794C2.5 8.54286 5.02542 6 8.1407 6ZM17.1485 6.33268C18.7061 6.33268 19.9688 8.72625 19.9688 11.6794C19.9688 14.6318 18.7063 17.0261 17.1487 17.0261C15.591 17.0261 14.3283 14.6318 14.3283 11.6794C14.3283 8.72702 15.5908 6.33268 17.1485 6.33268ZM21.5081 6.88959C22.0558 6.88959 22.5 9.03403 22.5 11.6794C22.5 14.324 22.056 16.4692 21.5081 16.4692C20.9602 16.4692 20.5163 14.3246 20.5163 11.6794C20.5163 9.03422 20.9604 6.88959 21.5081 6.88959Z" fill="white" fillOpacity="0.9"/>
  </svg>

);

export default MediumBtn;


