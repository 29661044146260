const Uma = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#FF4A4A"/>
    <path d="M19.7714 19.2H18.6436C18.5933 19.2001 18.5435 19.1906 18.497 19.1723C18.4505 19.1539 18.4082 19.1269 18.3726 19.0929C18.3369 19.0589 18.3086 19.0184 18.2893 18.9739C18.27 18.9294 18.26 18.8817 18.2599 18.8335V15.9858L16.2804 17.1718C16.1989 17.2206 16.1047 17.2464 16.0087 17.2464C15.9126 17.2464 15.8184 17.2206 15.7369 17.1718L13.757 15.9856V18.8335C13.7569 18.8817 13.7469 18.9294 13.7276 18.9739C13.7083 19.0184 13.68 19.0589 13.6444 19.0929C13.6087 19.1269 13.5665 19.1539 13.52 19.1723C13.4735 19.1906 13.4236 19.2001 13.3733 19.2H12.2455C12.1952 19.2001 12.1454 19.1906 12.0989 19.1723C12.0524 19.1539 12.0101 19.1269 11.9745 19.0929C11.9389 19.0589 11.9106 19.0184 11.8913 18.9739C11.8719 18.9294 11.862 18.8817 11.8619 18.8335V13.3448C11.8622 13.2557 11.8878 13.1683 11.936 13.0922C11.9842 13.016 12.0531 12.9538 12.1354 12.9124C12.2178 12.8709 12.3103 12.8518 12.4031 12.8569C12.4959 12.8621 12.5855 12.8914 12.6623 12.9417L15.7918 14.9897C15.8557 15.0315 15.9313 15.0539 16.0087 15.0539C16.086 15.0539 16.1616 15.0315 16.2255 14.9897L19.3544 12.9417C19.4312 12.8913 19.5208 12.8619 19.6136 12.8567C19.7065 12.8514 19.7991 12.8705 19.8814 12.912C19.9638 12.9534 20.0328 13.0156 20.081 13.0918C20.1292 13.1681 20.1548 13.2554 20.155 13.3446V18.8335C20.155 18.8817 20.145 18.9294 20.1256 18.9739C20.1063 19.0184 20.078 19.0589 20.0424 19.0929C20.0068 19.1269 19.9645 19.1539 19.918 19.1723C19.8715 19.1906 19.8217 19.2001 19.7714 19.2Z" fill="white"/>
    <path d="M10.1628 19.1511H3.17575C3.10897 19.1512 3.04282 19.1387 2.9811 19.1142C2.91937 19.0898 2.86327 19.054 2.816 19.0088C2.76874 18.9636 2.73123 18.9099 2.70562 18.8508C2.68001 18.7917 2.6668 18.7283 2.66675 18.6644V13.165C2.6668 13.117 2.67671 13.0695 2.69592 13.0252C2.71513 12.9809 2.74326 12.9406 2.77871 12.9067C2.81415 12.8728 2.85622 12.846 2.9025 12.8277C2.94878 12.8093 2.99838 12.7999 3.04845 12.8H4.17098C4.22106 12.7999 4.27065 12.8093 4.31693 12.8277C4.36322 12.846 4.40528 12.8728 4.44073 12.9067C4.47617 12.9406 4.5043 12.9809 4.52351 13.0252C4.54272 13.0695 4.55263 13.117 4.55268 13.165V17.3482H8.78587V13.165C8.7859 13.117 8.79581 13.0694 8.81503 13.0251C8.83426 12.9807 8.86242 12.9405 8.89791 12.9065C8.9334 12.8726 8.97552 12.8458 9.02186 12.8275C9.0682 12.8092 9.11785 12.7999 9.16796 12.8H10.2905C10.3406 12.7999 10.3902 12.8093 10.4364 12.8277C10.4827 12.846 10.5248 12.8728 10.5602 12.9067C10.5957 12.9406 10.6238 12.9809 10.643 13.0252C10.6622 13.0695 10.6721 13.117 10.6722 13.165V18.6645C10.6721 18.7285 10.6589 18.7918 10.6333 18.8509C10.6077 18.91 10.5701 18.9637 10.5229 19.0088C10.4756 19.054 10.4195 19.0898 10.3578 19.1143C10.2961 19.1387 10.2296 19.1512 10.1628 19.1511Z" fill="white"/>
    <path d="M21.8374 12.8H28.8252C28.892 12.7999 28.9581 12.8124 29.0198 12.8369C29.0815 12.8613 29.1376 12.8971 29.1849 12.9423C29.2321 12.9875 29.2696 13.0411 29.2953 13.1002C29.3209 13.1593 29.3341 13.2226 29.3342 13.2866V18.7867C29.3341 18.8347 29.3242 18.8822 29.305 18.9265C29.2858 18.9708 29.2577 19.011 29.2222 19.0449C29.1868 19.0788 29.1447 19.1057 29.0984 19.124C29.0522 19.1423 29.0026 19.1517 28.9525 19.1517H27.8296C27.7795 19.1518 27.7298 19.1424 27.6835 19.1241C27.6372 19.1058 27.5951 19.079 27.5596 19.0451C27.5241 19.0112 27.4959 18.9709 27.4767 18.9266C27.4575 18.8822 27.4475 18.8347 27.4475 18.7867V14.8461C27.4472 14.7816 27.4202 14.7199 27.3725 14.6745C27.3248 14.629 27.2602 14.6036 27.1929 14.6037H23.4695C23.4022 14.6036 23.3376 14.629 23.2899 14.6745C23.2422 14.7199 23.2152 14.7816 23.2149 14.8461V18.7867C23.2148 18.8347 23.2049 18.8822 23.1857 18.9265C23.1665 18.9708 23.1384 19.011 23.1029 19.0449C23.0675 19.0788 23.0254 19.1057 22.9791 19.124C22.9328 19.1423 22.8833 19.1517 22.8332 19.1517H21.7101C21.66 19.1517 21.6104 19.1423 21.5641 19.124C21.5178 19.1057 21.4758 19.0788 21.4403 19.0449C21.4049 19.011 21.3767 18.9708 21.3575 18.9265C21.3383 18.8822 21.3284 18.8347 21.3284 18.7867V13.2866C21.3284 13.2226 21.3417 13.1593 21.3673 13.1002C21.3929 13.0411 21.4304 12.9875 21.4777 12.9423C21.5249 12.8971 21.581 12.8613 21.6428 12.8369C21.7045 12.8124 21.7706 12.7999 21.8374 12.8Z" fill="white"/>
    <path d="M24.3876 16.1425V17.2253C24.3879 17.2731 24.398 17.3205 24.4173 17.3646C24.4366 17.4087 24.4649 17.4487 24.5003 17.4823C24.5358 17.516 24.5779 17.5426 24.6241 17.5607C24.6703 17.5787 24.7198 17.5879 24.7697 17.5877H25.8921C25.942 17.5879 25.9915 17.5787 26.0377 17.5607C26.0839 17.5426 26.1259 17.516 26.1614 17.4823C26.1969 17.4487 26.2251 17.4087 26.2445 17.3646C26.2638 17.3205 26.2739 17.2731 26.2741 17.2253V16.1425C26.2739 16.0946 26.2638 16.0473 26.2445 16.0032C26.2251 15.9591 26.1969 15.9191 26.1614 15.8855C26.1259 15.8518 26.0839 15.8252 26.0377 15.8071C25.9915 15.789 25.942 15.7799 25.8921 15.7801H24.7697C24.7198 15.7799 24.6703 15.789 24.6241 15.8071C24.5779 15.8252 24.5358 15.8518 24.5003 15.8855C24.4649 15.9191 24.4366 15.9591 24.4173 16.0032C24.398 16.0473 24.3879 16.0946 24.3876 16.1425Z" fill="white"/>
  </svg>

);

export default Uma;
