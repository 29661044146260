const Usdc = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2755_121803)">
      <path d="M16 32C24.8667 32 32 24.8667 32 16C32 7.13328 24.8667 0 16 0C7.13328 0 0 7.13328 0 16C0 24.8667 7.13328 32 16 32Z" fill="#2775CA"/>
      <path d="M20.4001 18.5333C20.4001 16.2 19.0001 15.4 16.2001 15.0667C14.2001 14.8 13.8001 14.2667 13.8001 13.3333C13.8001 12.3998 14.4668 11.8 15.8001 11.8C17.0001 11.8 17.6668 12.2 18.0001 13.2C18.0668 13.4 18.2668 13.5333 18.4668 13.5333H19.5334C19.8001 13.5333 20.0001 13.3333 20.0001 13.0667V13C19.7334 11.5333 18.5334 10.4 17.0001 10.2667V8.66671C17.0001 8.39999 16.8001 8.19999 16.4668 8.13327H15.4668C15.2001 8.13327 15.0001 8.33327 14.9334 8.66671V10.2C12.9334 10.4667 11.6668 11.8 11.6668 13.4667C11.6668 15.6667 13.0001 16.5333 15.8001 16.8667C17.6668 17.2 18.2668 17.6 18.2668 18.6667C18.2668 19.7334 17.3334 20.4667 16.0668 20.4667C14.3334 20.4667 13.7334 19.7333 13.5334 18.7333C13.4668 18.4667 13.2668 18.3333 13.0668 18.3333H11.9334C11.6668 18.3333 11.4668 18.5333 11.4668 18.8V18.8667C11.7334 20.5333 12.8001 21.7333 15.0001 22.0667V23.6667C15.0001 23.9333 15.2001 24.1333 15.5334 24.2H16.5334C16.8001 24.2 17.0001 24 17.0668 23.6667V22.0667C19.0668 21.7333 20.4001 20.3333 20.4001 18.5333V18.5333Z" fill="white"/>
      <path d="M12.6001 25.5333C7.40008 23.6667 4.73336 17.8667 6.6668 12.7333C7.6668 9.93328 9.8668 7.8 12.6001 6.8C12.8668 6.66672 13.0001 6.46672 13.0001 6.13328V5.2C13.0001 4.93328 12.8668 4.73328 12.6001 4.66672C12.5334 4.66672 12.4001 4.66672 12.3334 4.73328C6.00008 6.73328 2.53336 13.4667 4.53336 19.8C5.73336 23.5333 8.60008 26.4 12.3334 27.6C12.6001 27.7333 12.8668 27.6 12.9334 27.3333C13.0001 27.2667 13.0001 27.2 13.0001 27.0667V26.1333C13.0001 25.9333 12.8001 25.6667 12.6001 25.5333ZM19.6668 4.73328C19.4001 4.6 19.1334 4.73328 19.0668 5C19.0001 5.06672 19.0001 5.13328 19.0001 5.26672V6.2C19.0001 6.46672 19.2001 6.73328 19.4001 6.86672C24.6001 8.73328 27.2668 14.5333 25.3334 19.6667C24.3334 22.4667 22.1334 24.6 19.4001 25.6C19.1334 25.7333 19.0001 25.9333 19.0001 26.2667V27.2C19.0001 27.4667 19.1334 27.6667 19.4001 27.7333C19.4668 27.7333 19.6001 27.7333 19.6668 27.6667C26.0001 25.6667 29.4668 18.9333 27.4668 12.6C26.2668 8.8 23.3334 5.93328 19.6668 4.73328V4.73328Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_2755_121803">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default Usdc;
