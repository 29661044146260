const GeneralBGFeature = (props) => (
  <svg width="699" height="2022" viewBox="0 0 699 2022" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_2860_122895)">
      <path d="M430.424 895.133C340.168 1227.13 460.867 1548.97 700.013 1613.98C939.16 1679 788.14 1289.82 878.396 957.828C968.652 625.832 1266.01 476.733 1026.86 411.719C787.714 346.705 520.681 563.136 430.424 895.133Z" fill="#A34EFC" fillOpacity="0.2"/>
    </g>
    <defs>
      <filter id="filter0_f_2860_122895" x="0.486328" y="0.185547" width="1517.42" height="2020.98" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_2860_122895"/>
      </filter>
    </defs>
  </svg>

);

export default GeneralBGFeature;


