// core
import React from 'react';

// components
import loader from "../../../assets/images/Loader.gif";

// styles
import './index.scss'


const Loader = ({active}) => {
  return (
    <div className={'loader' + (active ? ' loader--active': '')}>
      <div className="loader__content">
        <img src={loader} alt=''/>
      </div>
    </div>
  );
};

export default Loader;
