// core
import React from 'react';

// styles
import './index.scss'

const HomeBgTop = () => {
  return (
    <div className="home-bg-top">
      <svg width="352" height="581" viewBox="0 0 352 581" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_2742_119729)">
          <path d="M-96.7489 82.0501C75.5574 16.8528 249.83 55.4188 292.5 168.19C335.171 280.96 230.08 425.232 57.7737 490.429C-155.11 403.411 -530.069 608.35 -572.739 495.579C-615.409 382.808 -269.055 147.247 -96.7489 82.0501Z" fill="black"/>
        </g>
        <defs>
          <filter id="filter0_f_2742_119729" x="-626.348" y="0.341949" width="978.348" height="580.051" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_2742_119729"/>
          </filter>
        </defs>
      </svg>

      <svg width="828" height="581" viewBox="0 0 828 581" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_2742_119728)">
          <path d="M530.253 82.0501C702.559 16.8528 876.832 55.4188 919.502 168.19C962.173 280.96 857.082 425.232 684.776 490.429C471.892 403.411 96.9334 608.35 54.2631 495.579C11.5929 382.808 357.947 147.247 530.253 82.0501Z" fill="black"/>
        </g>
        <defs>
          <filter id="filter0_f_2742_119728" x="0.654053" y="0.341949" width="978.348" height="580.051" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_2742_119728"/>
          </filter>
        </defs>
      </svg>

      <svg width="281" height="859" viewBox="0 0 281 859" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_2742_119727)">
          <path d="M537.059 425.424C537.059 609.653 439.314 758.999 318.741 758.999C198.167 758.999 100.423 609.653 100.423 425.424C257.148 257.112 198.167 -166.107 318.741 -166.107C439.314 -166.107 537.059 241.196 537.059 425.424Z" fill="#F2AD7D"/>
        </g>
        <defs>
          <filter id="filter0_f_2742_119727" x="0.422852" y="-266.107" width="636.636" height="1125.11" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_2742_119727"/>
          </filter>
        </defs>
      </svg>

      <svg width="743" height="472" viewBox="0 0 743 472" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_2742_119726)">
          <ellipse cx="440.457" cy="37.5751" rx="340.283" ry="333.575" fill="#A095FE"/>
        </g>
        <defs>
          <filter id="filter0_f_2742_119726" x="0.173584" y="-396" width="880.567" height="867.15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_2742_119726"/>
          </filter>
        </defs>
      </svg>

      <svg width="1112" height="472" viewBox="0 0 1112 472" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_2742_119725)">
          <path d="M1011.34 146.124C1011.34 330.352 858.985 371.15 671.052 371.15C483.119 371.15 92.9365 261.442 92.9365 77.2136C92.9365 -107.015 483.119 -187.451 671.052 -187.451C858.985 -187.451 1011.34 -38.1045 1011.34 146.124Z" fill="#A34EFC"/>
        </g>
        <defs>
          <filter id="filter0_f_2742_119725" x="-7.06348" y="-287.451" width="1118.4" height="758.601" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_2742_119725"/>
          </filter>
        </defs>
      </svg>

      <svg width="502" height="580" viewBox="0 0 502 580" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_2742_119724)">
          <ellipse cx="61.2256" cy="146.124" rx="340.283" ry="333.575" fill="#1E0FF0"/>
        </g>
        <defs>
          <filter id="filter0_f_2742_119724" x="-379.058" y="-287.451" width="880.566" height="867.15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_2742_119724"/>
          </filter>
        </defs>
      </svg>



    </div>
  );
};

export default HomeBgTop;
