const Feature1 = (props) => (
  <svg width="82" height="80" viewBox="0 0 82 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="80.3333" height="79" rx="7.5" fill="#030110" stroke="url(#paint0_linear_2846_122495)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M42.9621 46.2603C36.9583 48.1937 33.4343 38.9283 40.1093 36.7427C43.1485 35.7434 45.7123 37.9289 46.2996 40.1799C47.1013 43.2715 45.1715 45.5504 42.9621 46.2603ZM38.5152 41.7957C38.1982 37.6954 44.202 37.4339 44.463 41.2073C44.7427 45.2796 38.8042 45.5598 38.5152 41.7957Z" fill="url(#paint1_linear_2846_122495)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M55.6969 44.1959C57.0953 36.1168 52.3314 30.2512 46.8497 28.0189C40.2959 25.357 32.5674 28.1684 29.2578 33.7257C23.4032 43.5515 29.8358 55.4507 40.8925 55.9738C45.066 56.1725 47.126 54.9249 49.8166 53.2952C50.0349 53.163 50.2574 53.0282 50.4855 52.8916C53.7298 53.8909 55.8181 53.2838 57.2351 50.9488C59.0157 47.9974 57.1046 45.7651 55.7062 44.1866L55.6969 44.1959ZM51.9212 45.7744C53.5993 45.0739 55.1934 46.0079 55.7248 47.3342C57.2538 51.1356 51.6322 53.1811 50.2152 49.6505C49.8609 48.7632 49.9448 47.8012 50.3363 47.054C50.7279 46.3068 51.1474 46.092 51.9119 45.7651L51.9212 45.7744ZM48.9286 51.6213L48.9259 51.6225C48.805 51.2641 48.6677 50.9835 48.5364 50.715C48.2628 50.1557 48.0152 49.6496 47.9964 48.6045C47.9404 45.2981 50.3736 43.5515 53.795 43.5141C54.4942 40.2451 53.3009 36.6772 51.9026 34.5663C46.7378 26.7954 35.5227 27.4025 30.9266 34.8092C27.6637 40.077 28.8756 47.1754 32.9962 50.706C34.8421 52.2751 37.1821 53.6761 40.501 53.947C44.0969 54.2424 45.8287 53.2816 47.4596 52.3767C47.942 52.1091 48.4155 51.8464 48.9259 51.6225L48.9286 51.6306V51.6213Z" fill="url(#paint2_linear_2846_122495)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.7918 64.9685C32.7135 65.367 33.6605 65.7765 34.8141 66.0892C36.688 66.5935 38.6457 66.9111 40.6874 66.9858C51.4551 67.3501 61.4023 60.6719 64.9729 50.5192C70.6504 34.3516 59.603 20.0519 46.57 17.5114C45.8242 15.1764 44.7241 12.9814 41.4985 13.0001C39.5314 13.0095 37.7601 14.2423 37.0609 15.7461C36.8513 16.1964 36.7674 16.5249 36.7034 16.7753C36.571 17.2935 36.524 17.4776 35.6252 17.7169C35.4988 17.7518 35.3704 17.7806 35.2437 17.809C35.0994 17.8414 34.9575 17.8732 34.8234 17.913C30.6655 19.0151 25.9017 21.8732 23.2634 24.9367C21.2124 27.3091 19.8047 29.1678 18.4902 32.3621C14.6959 41.5808 16.5511 50.1923 22.154 57.8138C22.0667 58.277 21.9442 58.6384 21.83 58.9748C21.5782 59.7172 21.3677 60.338 21.6692 61.662C22.3964 64.8656 26.0322 66.855 29.9197 64.2211L29.9104 64.2118C30.5628 64.4372 31.1716 64.7004 31.7918 64.9685ZM25.4635 57.7298C29.3883 56.254 30.9545 61.9515 27.6077 63.3058C23.9066 64.8002 21.7531 59.1308 25.4635 57.7298ZM23.2074 55.8991C21.1005 53.5641 19.4597 48.9875 18.9004 46.0267C18.0706 41.6462 18.7232 36.808 20.2894 33.156C22.154 28.8035 23.9626 27.0289 26.6102 24.4791C28.5493 22.6204 33.2106 19.9958 36.7905 19.5008C38.2262 24.0961 44.7427 24.0961 46.1878 19.5008C52.7043 20.4442 58.6242 25.768 61.0108 29.831C68.3104 42.2813 63.3041 57.2908 51.2872 62.8014C44.3046 66.0051 37.9559 65.5661 31.1503 62.5399C31.5605 60.0181 31.5232 58.0473 29.7985 56.7024C28.3442 55.5722 25.5474 54.825 23.7948 56.3848L23.2074 55.8898V55.8991ZM40.734 15.1297C42.3841 14.6346 44.0063 15.7741 44.3605 17.1098C44.8453 18.9217 43.7173 20.4442 42.3561 20.8458C38.6457 21.9479 36.7532 16.3158 40.734 15.1297Z" fill="url(#paint3_linear_2846_122495)"/>
    <defs>
      <linearGradient id="paint0_linear_2846_122495" x1="81.5" y1="-10" x2="-0.961179" y2="78.6407" gradientUnits="userSpaceOnUse">
        <stop stopColor="#140028"/>
        <stop offset="0.620002" stopColor="#2D8BFE"/>
        <stop offset="1" stopColor="#FCC0A0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2846_122495" x1="41.5" y1="13" x2="23.1798" y2="67.4074" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A34EFC"/>
        <stop offset="1" stopColor="#EFD8A9"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2846_122495" x1="41.5" y1="13" x2="23.1798" y2="67.4074" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A34EFC"/>
        <stop offset="1" stopColor="#EFD8A9"/>
      </linearGradient>
      <linearGradient id="paint3_linear_2846_122495" x1="41.5" y1="13" x2="23.1798" y2="67.4074" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A34EFC"/>
        <stop offset="1" stopColor="#EFD8A9"/>
      </linearGradient>
    </defs>
  </svg>


);

export default Feature1;


