const Erc = (props) => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="16.5" fill="url(#paint0_radial_2742_120959)"/>
    <g filter="url(#filter0_f_2561_112836)">
      <circle cx="24.5" cy="24.5" r="20" stroke="#CCAEFF"/>
    </g>
    <circle cx="24.5" cy="24.5" r="20" stroke="#8247E5"/>
    <defs>
      <filter id="filter0_f_2561_112836" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2561_112836"/>
      </filter>
      <radialGradient id="paint0_radial_2742_120959" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 24.5) rotate(90) scale(25)">
        <stop stopColor="#18F3FF"/>
        <stop offset="1" stopColor="#8247E5"/>
      </radialGradient>
    </defs>
  </svg>


);

export default Erc;
