const Feature4 = (props) => (
  <svg width="82" height="80" viewBox="0 0 82 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="80.3333" height="79" rx="7.5" fill="#030110" stroke="url(#paint0_linear_2845_122449)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M48.1352 35.9566C49.6981 38.1111 53.5973 43.1133 54.4494 45.4249C58.921 57.5572 41.7297 69.8412 30.8835 57.4744C28.5592 54.8243 26.9565 49.7865 28.5822 45.4164C29.3442 43.3671 30.4979 42.0653 31.5924 40.5375L38.1901 31.3569C38.7424 30.5567 40.8897 27.4026 41.5182 26.8896L48.1352 35.9566ZM56.5538 50.8888C56.8735 48.3215 56.3247 45.7349 55.3409 43.6723C54.6495 42.2225 45.2295 29.3136 44.4928 28.3001C42.0428 24.9303 41.856 23.7149 40.405 25.6815L29.5316 40.8234C26.728 44.8647 25.2316 48.7833 27.2931 54.2393C32.87 69.0005 54.632 66.3239 56.5532 50.8888H56.5538Z" fill="url(#paint1_linear_2845_122449)" stroke="url(#paint2_linear_2845_122449)" strokeWidth="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M39.7244 40.9529C44.8354 39.8198 48.8465 43.3879 49.7004 47.4007C51.9613 58.0312 35.8723 61.6633 33.3734 50.9519C32.1816 45.8451 35.8046 41.822 39.7251 40.9529H39.7244ZM40.5113 39.3115C35.3181 39.9376 31.0732 44.3149 31.7132 50.2448C32.2577 55.2935 36.9469 59.5868 42.7075 58.9142C47.7224 58.3285 52.0096 53.6612 51.311 47.9066C50.7151 42.998 46.1825 38.628 40.5113 39.3121V39.3115Z" fill="url(#paint3_linear_2845_122449)" stroke="url(#paint4_linear_2845_122449)" strokeWidth="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M59.0005 18.1417C59.8937 19.0096 65.2428 26.5603 65.6259 27.6409C67.2111 32.1143 63.1959 36.3332 59.0005 36.3459C54.8033 36.3586 50.7457 32.2055 52.3333 27.5943C52.676 26.5996 58.465 18.508 59.0005 18.1417ZM50.5287 30.4215C51.0019 35.2804 56.3994 39.4033 62.024 37.2851C66.5403 35.5844 69.3517 29.9779 65.8961 25.0162L59.7444 16.4381C58.604 15.1792 57.8903 16.9481 57.2533 17.8015C55.7847 19.7686 54.567 21.5188 53.1455 23.5005C51.8897 25.2507 50.2145 27.2027 50.5281 30.4221L50.5287 30.4215Z" fill="url(#paint5_linear_2845_122449)" stroke="url(#paint6_linear_2845_122449)" strokeWidth="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M24.0357 18.1417C24.5645 18.5079 30.3487 26.6056 30.7041 27.5949C32.3376 32.1384 28.2299 36.3579 24.0357 36.3465C19.8959 36.335 15.7628 32.1082 17.3927 27.6299C17.8665 26.3282 21.1536 22.0029 22.3254 20.3959C22.8119 19.7293 23.4465 18.7061 24.0351 18.1411L24.0357 18.1417ZM15.6056 30.4214C16.1 35.2888 21.431 39.3646 27.0798 37.3013C31.5888 35.6545 34.4214 29.8636 30.9494 24.9998C29.5401 23.026 28.3199 21.2631 26.8514 19.2906C24.1632 15.6796 24.4684 14.8141 22.3236 17.8389C20.9022 19.8435 19.6294 21.6439 18.2182 23.5397C16.8705 25.3503 15.2714 27.1356 15.6056 30.4208V30.4214Z" fill="url(#paint7_linear_2845_122449)" stroke="url(#paint8_linear_2845_122449)" strokeWidth="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.7074 46.6155L40.7612 48.353C40.0353 48.353 36.4389 48.0744 37.879 49.5847C38.4121 50.1437 39.9109 49.8712 40.6893 49.8712C40.8917 51.4135 40.3001 52.4747 41.5178 52.9805C42.7344 52.4723 42.1439 51.4135 42.3464 49.8712C43.0329 49.8712 44.5009 50.0543 45.0303 49.6886C47.0409 48.2986 42.9531 48.353 42.2751 48.353L42.0974 45.2987L40.9926 45.2817L40.7086 46.6161L40.7074 46.6155Z" fill="url(#paint9_linear_2845_122449)" stroke="url(#paint10_linear_2845_122449)" strokeWidth="0.5"/>
    <defs>
      <linearGradient id="paint0_linear_2845_122449" x1="41.1667" y1="1.90681e-06" x2="65.1747" y2="80.3958" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="0.620002" stopColor="#2D8BFE"/>
        <stop offset="1" stopColor="#FCC0A0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2845_122449" x1="41.4891" y1="24.7371" x2="61.5169" y2="39.0888" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2845_122449" x1="41.4891" y1="24.7371" x2="61.5169" y2="39.0888" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint3_linear_2845_122449" x1="41.5176" y1="39.2412" x2="52.1229" y2="49.0822" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint4_linear_2845_122449" x1="41.5176" y1="39.2412" x2="52.1229" y2="49.0822" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint5_linear_2845_122449" x1="58.9644" y1="16" x2="70.1285" y2="24.0367" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint6_linear_2845_122449" x1="58.9644" y1="16" x2="70.1285" y2="24.0367" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint7_linear_2845_122449" x1="24.0297" y1="16.0012" x2="35.1895" y2="24.0315" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint8_linear_2845_122449" x1="24.0297" y1="16.0012" x2="35.1895" y2="24.0315" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint9_linear_2845_122449" x1="41.5617" y1="45.2817" x2="45.7068" y2="49.2995" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
      <linearGradient id="paint10_linear_2845_122449" x1="41.5617" y1="45.2817" x2="45.7068" y2="49.2995" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24F2F2"/>
        <stop offset="1" stopColor="#2D8BFE"/>
      </linearGradient>
    </defs>
  </svg>

);

export default Feature4;


