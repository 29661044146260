const StakingCard3 = (props) => (
  <svg width="53" height="68" viewBox="0 0 53 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2772_117768)">
      <path d="M27.5 1C12.5883 1 0.5 13.0883 0.5 28C0.5 42.9117 12.5883 55 27.5 55V1Z" stroke="#A34EFC"/>
      <path d="M23.857 28.8167L27 27.0464V30.1564L23.3588 32.2758L23.3561 32.2773L17.8953 35.4118C17.2667 35.7509 16.5464 35.7385 16.0112 35.4192L16.0113 35.4191L16.003 35.4144L7.94022 30.8099L7.94027 30.8098L7.93078 30.8046C7.32871 30.4778 7 29.8843 7 29.2206V19.9418C7 19.2773 7.32881 18.684 7.93057 18.3577L7.93062 18.3578L7.94022 18.3523L15.9979 13.7507C16.6269 13.4115 17.3475 13.4239 17.8827 13.7431L17.8827 13.7431L17.8875 13.7459L25.8092 18.3504L25.8153 18.354L25.8216 18.3574C26.4233 18.6845 26.752 19.2775 26.752 19.9418V22.8628L24.0363 24.4434V22.1037C24.0363 21.0706 23.436 20.1489 22.6008 19.6508L22.6009 19.6507L22.5927 19.6461L18.3162 17.2042L18.3162 17.2041L18.3067 17.1989C17.3961 16.7047 16.2979 16.6923 15.4326 17.2064L11.1645 19.6435C10.2261 20.1552 9.7157 21.0944 9.7157 22.1037V27.0576C9.7157 28.0907 10.317 29.0123 11.1518 29.5104L11.1517 29.5104L11.1578 29.5139L15.5049 32.0256L15.5048 32.0258L15.5165 32.0322C16.4282 32.5269 17.5277 32.5388 18.3935 32.023C18.394 32.0227 18.3945 32.0224 18.395 32.0221L23.857 28.8167ZM25.047 40.6473L25.0471 40.6472L25.0378 40.6422C24.4365 40.3154 24.1077 39.7217 24.1077 39.058V36.1361L26.8232 34.5555V36.8954C26.8232 37.2295 26.8866 37.553 27 37.8532V41.7626L25.047 40.6473Z" stroke="#A34EFC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5 47C37.4411 47 45.5 38.9411 45.5 29C45.5 19.0589 37.4411 11 27.5 11V12C36.8888 12 44.5 19.6112 44.5 29C44.5 38.3888 36.8888 46 27.5 46V47Z" fill="#18F3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5 41C34.1274 41 39.5 35.6274 39.5 29C39.5 22.3726 34.1274 17 27.5 17V18C33.5751 18 38.5 22.9249 38.5 29C38.5 35.0751 33.5751 40 27.5 40V41Z" fill="#18F3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.5344 6.93726L27.7622 11.4065L28.612 11.9336L31.052 7.99997H38.5V6.99997H30.6355L30.5344 6.93726Z" fill="#18F3FF"/>
      <circle cx="41" cy="7.5" r="3" stroke="#18F3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.2722 50.9963L27.5 46.5271L28.3498 46L30.7898 49.9336H38.2378V50.9336H30.3733L30.2722 50.9963Z" fill="#18F3FF"/>
      <circle r="3" transform="matrix(1 0 0 -1 40.7378 50.4336)" stroke="#18F3FF"/>
    </g>
    <defs>
      <filter id="filter0_d_2772_117768" x="0" y="0.5" width="52.5" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="5" dy="10"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2772_117768"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2772_117768" result="shape"/>
      </filter>
    </defs>
  </svg>
);

export default StakingCard3;


