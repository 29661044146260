// core
import {useTranslation} from "react-i18next";

// components
import Eth from "../../../components/icons/crypto/Eth";
import Bnb from "../../../components/icons/crypto/Bnb";
import Polygon from "../../../components/icons/crypto/Polygon";
import Erc from "../../../components/icons/crypto/Erc";
import Usdt from "../../../components/icons/crypto/Usdt";
import Usdc from "../../../components/icons/crypto/Usdc";
import Aave from "../../../components/icons/crypto/Aave";
import Uma from "../../../components/icons/crypto/Uma";
import Dc from "../../../components/icons/crypto/Dc";
import Tusd from "../../../components/icons/crypto/Tusd";

// styles
import './index.scss'

const HomeWorked = () => {
  const {t} = useTranslation();
  return (
    <div className="home-worked">
      <h2 className="home-worked__title">
        {t('modules.home.worked.title')}
      </h2>
      <div className="home-worked__list">
        <div className="home-worked__list-item home-worked__card">
          <div className="home-worked__card-image">

            <Polygon/>
          </div>
          <span>
            {t('modules.home.worked.list.item1')}
          </span>
        </div>

        <div className="home-worked__list-item home-worked__card">
          <div className="home-worked__card-image">
            <Eth/>
          </div>
          <span>
             {t('modules.home.worked.list.item2')}
          </span>
        </div>

        <div className="home-worked__list-item home-worked__card">
          <div className="home-worked__card-image">

            <Bnb/>
          </div>
          <span>
            {t('modules.home.worked.list.item3')}
          </span>
        </div>

        <div className="home-worked__list-item home-worked__card">
          <div className="home-worked__card-image">
            <Erc/>
          </div>
          <span>
           {t('modules.home.worked.list.item4')}
          </span>
        </div>

        <div className="home-worked__list-item home-worked__group">
          <div className="home-worked__card">
            <div className="home-worked__card-image">
              <Usdt/>
            </div>
          </div>
          <div className="home-worked__card">
            <div className="home-worked__card-image">
              <Usdc/>
            </div>
          </div>

          <div className="home-worked__card">
            <div className="home-worked__card-image">
              <Aave/>
            </div>
          </div>

          <div className="home-worked__card">
            <div className="home-worked__card-image">
              <Uma/>
            </div>
          </div>
          <div className="home-worked__card">
            <div className="home-worked__card-image">
              <Dc/>
            </div>
          </div>

          <div className="home-worked__card">
            <div className="home-worked__card-image">
              <Tusd/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWorked;
