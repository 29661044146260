// core
import React from 'react';
import {useWindowSize} from 'react-use';
import {useTranslation} from "react-i18next";
import {Player} from '@lottiefiles/react-lottie-player';

// utils
import {goToPlatformLink} from "../../../utils/common";

// components
import AppButton from "../../../components/atoms/Button";
import HeadBannerBG1 from "../../../components/images/HeadBannerBG1";
import HeadBannerBG2 from "../../../components/images/HeadBannerBG2";
import homeHeadAnimation from '../../../assets/lottie/homeHead.json'

// styles
import './index.scss'

const HomeHead = () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();
  return (
    <div className="home-head">
      <div className="home-head__title">
        <h1 dangerouslySetInnerHTML={{__html: t('modules.home.head.title')}}/>
        {width > 1024 && (<AppButton onClick={() => goToPlatformLink()} className="home-head__btn" size={'big'}>{t('actions.play')}</AppButton>)}
      </div>
      <div className="home-head__image">
        <div className="home-head__image-bg">
          <HeadBannerBG1/>
          <HeadBannerBG2/>
        </div>
        <div className="home-head__image-content">
          <Player
            autoplay
            loop
            src={homeHeadAnimation}
          >
          </Player>
        </div>
      </div>
      {width <= 1024 && (<AppButton onClick={() => goToPlatformLink()} className="home-head__btn" size={'big'}>{t('actions.play')}</AppButton>)}

      <div className="home-head__sub-title">
        {t('modules.home.head.subTitle')}
      </div>
      <div className="home-head__text">
        {t('modules.home.head.text')}
      </div>
    </div>
  );
};

export default HomeHead;
