const GeneralBGHow = (props) => (
  <svg width="1520" height="1369" viewBox="0 0 1520 1369" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_2860_122897)">
      <path d="M755.872 400.941C411.869 406.431 136.206 611.759 140.161 859.553C144.115 1107.35 476.043 854.193 820.045 848.703C1164.05 843.213 1389.86 1087.47 1385.9 839.672C1381.95 591.877 1099.87 395.451 755.872 400.941Z" fill="#A32970" fillOpacity="0.2"/>
    </g>
    <defs>
      <filter id="filter0_f_2860_122897" x="-259.881" y="0.828247" width="2045.84" height="1367.29" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_2860_122897"/>
      </filter>
    </defs>
  </svg>


);

export default GeneralBGHow;


