const YouTube = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-2-inside-1_3110_125191" fill="white">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9 8C6.79086 8 5 9.79086 5 12V20C5 22.2091 6.79087 24 9 24H22.6C24.8091 24 26.6 22.2091 26.6 20V12C26.6 9.79086 24.8091 8 22.6 8H9ZM14.6831 11.4686C13.6196 10.7644 12.2 11.527 12.2 12.8026V19.1974C12.2 20.4729 13.6195 21.2356 14.6831 20.5315L19.4721 17.3609C20.4468 16.7156 20.4469 15.2849 19.4722 14.6395L14.6831 11.4686Z"/>
    </mask>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9 8C6.79086 8 5 9.79086 5 12V20C5 22.2091 6.79087 24 9 24H22.6C24.8091 24 26.6 22.2091 26.6 20V12C26.6 9.79086 24.8091 8 22.6 8H9ZM14.6831 11.4686C13.6196 10.7644 12.2 11.527 12.2 12.8026V19.1974C12.2 20.4729 13.6195 21.2356 14.6831 20.5315L19.4721 17.3609C20.4468 16.7156 20.4469 15.2849 19.4722 14.6395L14.6831 11.4686Z"
          fill="#D9D9D9"/>
    <path
      d="M14.6831 11.4686L15.2352 10.6348V10.6348L14.6831 11.4686ZM14.6831 20.5315L15.2351 21.3653L14.6831 20.5315ZM19.4721 17.3609L20.0242 18.1947V18.1947L19.4721 17.3609ZM19.4722 14.6395L18.9201 15.4733L19.4722 14.6395ZM6 12C6 10.3431 7.34315 9 9 9V7C6.23858 7 4 9.23858 4 12H6ZM6 20V12H4V20H6ZM9 23C7.34315 23 6 21.6569 6 20H4C4 22.7614 6.23858 25 9 25V23ZM22.6 23H9V25H22.6V23ZM25.6 20C25.6 21.6569 24.2569 23 22.6 23V25C25.3614 25 27.6 22.7614 27.6 20H25.6ZM25.6 12V20H27.6V12H25.6ZM22.6 9C24.2569 9 25.6 10.3431 25.6 12H27.6C27.6 9.23857 25.3614 7 22.6 7V9ZM9 9H22.6V7H9V9ZM13.2 12.8026C13.2 12.3243 13.7323 12.0383 14.1311 12.3024L15.2352 10.6348C13.5069 9.49045 11.2 10.7298 11.2 12.8026H13.2ZM13.2 19.1974V12.8026H11.2V19.1974H13.2ZM14.131 19.6976C13.7323 19.9616 13.2 19.6757 13.2 19.1974H11.2C11.2 21.2702 13.5068 22.5095 15.2351 21.3653L14.131 19.6976ZM18.9201 16.5271L14.131 19.6976L15.2351 21.3653L20.0242 18.1947L18.9201 16.5271ZM18.9201 15.4733C19.2975 15.7232 19.2975 16.2772 18.9201 16.5271L20.0242 18.1947C21.5961 17.154 21.5962 14.8465 20.0243 13.8057L18.9201 15.4733ZM14.1311 12.3024L18.9201 15.4733L20.0243 13.8057L15.2352 10.6348L14.1311 12.3024Z"
      fill="#D9D9D9" mask="url(#path-2-inside-1_3110_125191)"/>
  </svg>
);

export default YouTube;
