// core
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

// utils


// service

// components
import PageLoader from "../../components/atoms/PageLoader";
import InfluencerPartner from "./InfluencerPartner";
import InfluencerHow from "./InfluencerHow";
import InfluencerOffer from "./InfluencerOffer";
import InfluencerGet from "./InfluencerGet";
import InfluencerModal from "../../components/features/InfluencerModal";

// styles
import './index.scss'

const Influencer = () => {

  const [isLoading, setLoading] = useState(true)
  const [isActiveModal, setIsActiveModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openModalHandler = () => {
    setIsActiveModal(true);
  }

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400)
  }

  // @ts-ignore
  return (
    <div className='influencer'>
      <PageLoader active={isLoading}/>

      <div className='app__wrapper'>
        <InfluencerPartner onClick={openModalHandler}/>
        <InfluencerHow/>
        <InfluencerOffer/>
        <InfluencerGet onClick={openModalHandler}/>
      </div>

      {isActiveModal &&
      ReactDOM.createPortal(
        <InfluencerModal
          isActive={isActiveModal}
          onClose={closeModalHandler}
        />,
        document.getElementById('modal'),
      )}
    </div>
  );
};

export default Influencer;


