// core
import {useTranslation} from "react-i18next";
import {useWindowSize} from 'react-use';

// components
import StakingBanner from "../../../components/images/StakingBanner";
import StakingCard1 from "../../../components/icons/StakingCard1";
import StakingCard2 from "../../../components/icons/StakingCard2";
import StakingCard3 from "../../../components/icons/StakingCard3";
import AppButton from "../../../components/atoms/Button";
import MediumBtn from "../../../components/icons/MediumBtn";

// styles
import './index.scss'

const HomeStaking= () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();

  return (
    <div className="home-staking">
      <div className="home-staking__grid">
        {width <= 1024 && (
          <>
            <h2 className="home-staking__title">
              {t('modules.home.staking.title')}
            </h2>
            <div className="home-staking__text">
              {t('modules.home.staking.text')}
            </div>
          </>
        )}
        <div className="home-staking__image">
          <StakingBanner/>
        </div>
        <div className="home-staking__content">
          {width > 1024 && (
            <>
              <h2 className="home-staking__title">
                {t('modules.home.staking.title')}
              </h2>
              <div className="home-staking__text">
                {t('modules.home.staking.text')}
              </div>
            </>
          )}

          <div className="home-staking__cards">
            <div className="home-staking__card">
              <div className="home-staking__card-image">
                <StakingCard1/>
              </div>
              <div className="home-staking__card-text">
                {t('modules.home.staking.list.item1')}
              </div>
            </div>

            <div className="home-staking__card">
              <div className="home-staking__card-image">
                <StakingCard2/>
              </div>
              <div className="home-staking__card-text">
                {t('modules.home.staking.list.item2')}
              </div>
            </div>

            <div className="home-staking__card">
              <div className="home-staking__card-image">
                <StakingCard3/>
              </div>
              <div className="home-staking__card-text">
                {t('modules.home.staking.list.item3')}
              </div>
            </div>
          </div>
          <div className="home-staking__actions">
            <div className="home-staking__actions-text">
              {t('modules.home.staking.actionsText')}
            </div>
            <AppButton href={'https://medium.com/@moonwinadv/liquidity-pool-how-to-get-passive-rewards-b2f5f2f115a4'}
                       outline
                       append={<MediumBtn/>}
                       className="home-staking__btn"
            >
              <span>{t('actions.readMore')}</span>
            </AppButton>
          </div>
        </div>
      </div>


    </div>
  );
};

export default HomeStaking;
