// core
// @ts-ignore
import React from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";

// utils
import {goToPlatformLink} from "../../../utils/common";

// components
import Logo from "../../images/Logo";
import AppButton from "../Button";

// styles
import './index.scss';

// func
const Header = () => {
  const {t} = useTranslation();
  const {pathname} = useLocation();

  return (
    <header className='header'>
      <div className='header__content'>
        <div className='header__logo'>
          <RouterLink to={'/'}>
            <Logo/>
          </RouterLink>
        </div>
        <div className='header__actions'>
          {pathname !== '/influencer' &&
          <AppButton
            to={'/influencer'}
            className="header__btn app-button--fill-2">
            {t('actions.becomePartner')}
          </AppButton>
          }

          <AppButton
            onClick={() => goToPlatformLink()}
            className="header__btn app-button--fill">
            {t('actions.launchGame')}
          </AppButton>
        </div>
      </div>
    </header>
  );
};

export default Header;
