// core
import React from 'react';
import {useWindowSize} from 'react-use';
import {useTranslation} from "react-i18next";


// components
import Reward from "../../../components/images/Reward";
import AppButton from "../../../components/atoms/Button";

// styles
import './index.scss'

const HomeProgram = () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();

  return (
    <div className="home-program">
      <div className="home-program__grid">
        <div className="home-program__content">
          <div className="home-program__title">
            {t('modules.home.program.title')}
          </div>
          <div className="home-program__text">
            {t('modules.home.program.text')}
          </div>

          {width > 1024 && (
            <div className="home-program__actions">
              <AppButton
                to={'/influencer'}
                size={'small'}
                className="home-program__btn">
                {t('actions.readMoreInfluencer')}
              </AppButton>
            </div>
          )}

        </div>


        <div className="home-program__banner">

          <div className="home-program__card home-program__card--1">
            <div className="home-program__card-line-2"/>
            <div className="home-program__card-line-1"/>

            <div className="home-program__card-content">
              <div className="home-program__card-title">
                {t('modules.home.program.list.item1.title')}
              </div>
              <div className="home-program__card-text">
                {t('modules.home.program.list.item1.text')}
              </div>
            </div>
            <div className="home-program__card-num">
              $10.345
            </div>
          </div>

          <div className="home-program__card home-program__card--2">
            <div className="home-program__card-line-2"/>
            <div className="home-program__card-line-1"/>
            <div className="home-program__card-content">
              <div className="home-program__card-title">
                {t('modules.home.program.list.item2.title')}
              </div>
            </div>
            <div className="home-program__card-num">
              20.345
            </div>
          </div>

          <div className="home-program__card home-program__card--3">
            <div className="home-program__card-line-2"/>
            <div className="home-program__card-line-1"/>
            <div className="home-program__card-content">
              <div className="home-program__card-title">
                {t('modules.home.program.list.item3.title')}
              </div>
              <div className="home-program__card-text">
                {t('modules.home.program.list.item3.text')}
              </div>
            </div>
            <div className="home-program__card-num">
              $54.345
            </div>
          </div>


          <div className="home-program__reward">
            <div className="home-program__reward-title">
              {t('modules.home.program.list.item4.title')}
            </div>
            <Reward/>
          </div>


          <div className="home-program__card home-program__card--4">
            <div className="home-program__card-line-2"/>
            <div className="home-program__card-line-1"/>
            <div className="home-program__card-content">
              <div className="home-program__card-title">
                {t('modules.home.program.list.item5.title')}
              </div>
              <div className="home-program__card-text">
                {t('modules.home.program.list.item5.text')}
              </div>
            </div>
            <div className="home-program__card-num">
              $10.345
            </div>
          </div>
        </div>

        {width <= 1024 && (
          <div className="home-program__actions">
            <AppButton
              to={'/influencer'}
              size={'small'}
              className="home-program__btn">
              {t('actions.readMoreInfluencer')}
            </AppButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeProgram;
