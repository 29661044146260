import axios  from 'axios';

const configApiClient = {
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'x-api-key': `${process.env.REACT_APP_API_KEY}`,
    'accept': 'application/json',
    'content-type': 'application/json'

},
  withCredentials: false,
};

const $apiClient = axios.create(configApiClient);


const configApi = {
  baseURL: `/api/`,
  headers: {
    'accept': 'application/json',
    'content-type': 'application/json'

  },
  withCredentials: false,
};

const $api = axios.create(configApi);

export { $apiClient, $api };
