const CardsBg3 = (props) => (
  <svg width="759" height="546" viewBox="0 0 759 546" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="807.134" y1="-82.2112" x2="0.615415" y2="546.648" stroke="black" strokeWidth="2"/>
      <line x1="814.513" y1="-72.7478" x2="7.99432" y2="556.111" stroke="black" strokeWidth="2"/>
      <line x1="821.891" y1="-63.2845" x2="15.3723" y2="565.574" stroke="black" strokeWidth="2"/>
      <line x1="829.271" y1="-53.8212" x2="22.7516" y2="575.038" stroke="black" strokeWidth="2"/>
      <line x1="836.65" y1="-44.358" x2="30.1306" y2="584.501" stroke="black" strokeWidth="2"/>
      <line x1="844.028" y1="-34.8946" x2="37.509" y2="593.964" stroke="black" strokeWidth="2"/>
      <line x1="851.407" y1="-25.4313" x2="44.8879" y2="603.428" stroke="black" strokeWidth="2"/>
      <line x1="858.786" y1="-15.9681" x2="52.2668" y2="612.891" stroke="black" strokeWidth="2"/>
      <line x1="866.164" y1="-6.5046" x2="59.6447" y2="622.354" stroke="black" strokeWidth="2"/>
      <line x1="873.543" y1="2.95859" x2="67.0241" y2="631.818" stroke="black" strokeWidth="2"/>
      <line x1="880.922" y1="12.4219" x2="74.403" y2="641.281" stroke="black" strokeWidth="2"/>
      <line x1="888.3" y1="21.8853" x2="81.7814" y2="650.744" stroke="black" strokeWidth="2"/>
      <line x1="895.679" y1="31.3486" x2="89.1603" y2="660.208" stroke="black" strokeWidth="2"/>
      <line x1="903.057" y1="40.8119" x2="96.5383" y2="669.671" stroke="black" strokeWidth="2"/>
      <line x1="910.437" y1="50.2753" x2="103.918" y2="679.134" stroke="black" strokeWidth="2"/>
      <line x1="917.816" y1="59.7385" x2="111.297" y2="688.597" stroke="black" strokeWidth="2"/>
      <line x1="925.194" y1="69.2019" x2="118.674" y2="698.061" stroke="black" strokeWidth="2"/>
      <line x1="932.573" y1="78.6651" x2="126.054" y2="707.524" stroke="black" strokeWidth="2"/>
      <line x1="939.952" y1="88.1284" x2="133.433" y2="716.987" stroke="black" strokeWidth="2"/>
      <line x1="947.33" y1="97.5918" x2="140.811" y2="726.451" stroke="black" strokeWidth="2"/>
      <line x1="954.709" y1="107.055" x2="148.19" y2="735.914" stroke="black" strokeWidth="2"/>
      <line x1="962.088" y1="116.518" x2="155.569" y2="745.377" stroke="black" strokeWidth="2"/>
  </svg>

);

export default CardsBg3;


