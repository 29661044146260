const How3 = (props) => (
  <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.90775 1.18214H23.5281C24.2852 1.18214 24.798 1.97336 25.6173 2.79538C26.2693 3.44937 26.8155 3.99292 27.4682 4.64624C28.6269 5.80496 30.0386 7.07412 31.0595 8.26698L24.8596 8.29108C24.8716 6.54196 25.0249 4.76539 24.7237 3.03904L23.7879 3.09259C23.5844 4.74799 23.5255 7.75623 23.8093 9.36277C25.7291 9.59907 29.8264 9.47389 31.9692 9.47389L31.9685 34.3518H5.90708C5.93787 32.9829 5.99611 33.3511 5.77053 32.0612L4.83472 32.1154C4.66469 33.4983 4.61583 34.0633 4.85614 35.4242L33.0402 35.4028C33.3213 33.1329 33.152 13.1381 33.152 9.17936C33.152 8.32789 32.7236 8.18197 32.2496 7.71339L26.5491 2.01286C25.5738 1.03756 24.8656 0 23.9726 0L4.83472 0.132539C4.59641 2.07578 4.54956 11.1708 4.86015 12.9507L5.79529 12.8958L5.90708 11.9928L5.90775 1.18214Z" fill="url(#paint0_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.6965 16.8318C13.7708 16.0848 13.4648 16.0051 13.0043 15.7019C9.95322 13.6917 6.52393 13.6997 3.55451 15.7072C-3.58187 20.5309 0.814692 32.2633 9.7678 30.6608C17.0622 29.3554 17.3286 20.5878 15.9101 20.2163C14.8023 19.9264 15.4047 22.045 15.3746 22.8048C15.2166 26.7756 11.9058 29.743 7.97918 29.6038C3.96351 29.4612 0.975346 26.04 1.18152 22.0665C1.38702 18.1037 4.84309 15.2005 8.71752 15.4114C12.1522 15.5981 12.5873 17.7562 13.6965 16.8311V16.8318Z" fill="url(#paint1_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.0517 26.7469C28.981 25.8974 30.2542 30.4613 27.2847 31.2954C24.3501 32.1201 22.9096 27.6579 26.0517 26.7469ZM23.1666 29.7612C24.0877 34.2193 30.9878 33.0017 30.1176 28.3374C29.2655 23.7694 22.1967 25.0654 23.1666 29.7612Z" fill="url(#paint2_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.85872 21.4567C4.69539 21.9902 4.68267 22.0866 5.01067 22.5076L7.04495 24.9228C7.71033 25.7709 8.02695 26.3071 8.6776 25.886L14.34 19.6808C14.9177 19.0776 15.5502 18.9029 15.2457 17.9055C14.2195 17.5869 13.9624 18.4899 13.2328 19.1687L8.31479 24.5057C7.9165 24.1878 7.16009 23.2158 6.74239 22.7091C6.4753 22.3851 6.26578 22.0859 5.93912 21.7304C5.52677 21.2826 5.51673 21.2545 4.85872 21.4567Z" fill="url(#paint3_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3366 15.2608L15.2316 15.4268L26.1935 15.3967L27.0965 15.2849L27.1514 14.3497L26.1989 14.1837L15.2737 14.2139C14.2924 14.2139 14.0822 14.4267 14.3372 15.2608H14.3366Z" fill="url(#paint4_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3361 12.8918L15.2311 13.0578L26.193 13.0277L27.096 12.9159L27.1509 11.9807L26.1984 11.8147L15.31 11.8448C14.2912 11.8448 14.0817 12.0577 14.3367 12.8918H14.3361Z" fill="url(#paint5_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1903 6.96837L11.0853 7.13438L21.6035 7.10426L22.3585 6.99247L22.4134 6.05733L21.4609 5.89132L11.0913 5.92144C10.1468 5.92144 9.93664 6.13431 10.1917 6.96837H10.1903Z" fill="url(#paint6_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7052 17.6298C17.5593 17.8975 26.2386 17.8969 27.1497 17.6291L27.0921 16.7187C26.24 16.4517 17.554 16.4517 16.6469 16.7187L16.7052 17.6291V17.6298Z" fill="url(#paint7_linear_5270_143710)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5214 30.6602C16.2731 30.8958 20.4187 30.8958 21.2273 30.6595L21.1697 29.7491C20.42 29.5142 16.2691 29.5135 15.4625 29.7491L15.5207 30.6595L15.5214 30.6602Z" fill="url(#paint8_linear_5270_143710)"/>
    <defs>
      <linearGradient id="paint0_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint1_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint2_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint3_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint4_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint5_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint6_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint7_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
      <linearGradient id="paint8_linear_5270_143710" x1="33.2025" y1="17.6635" x2="-1.21945e-06" y2="17.6629" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D2CD2" stopOpacity="0.8"/>
        <stop offset="1" stopColor="#F2AD7D"/>
      </linearGradient>
    </defs>
  </svg>

);

export default How3;


