const CardsBg2 = (props) => (
  <svg width="480" height="546" viewBox="0 0 480 546" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -327.046 -77.9998)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -334.425 -68.5364)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -341.803 -59.0731)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -349.182 -49.6098)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -356.561 -40.1466)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -363.94 -30.6832)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -371.319 -21.2199)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -378.698 -11.7567)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -386.076 -2.29321)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -393.455 7.16998)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -400.834 16.6333)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -408.212 26.0967)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -415.591 35.56)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -422.969 45.0233)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -430.348 54.4867)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -437.727 63.9499)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -445.106 73.4133)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -452.485 82.8765)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -459.864 92.3398)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -467.242 101.803)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -474.621 111.266)" stroke="black" strokeWidth="2"/>
    <line y1="-1" x2="1022.71" y2="-1" transform="matrix(0.788609 0.614895 0.614894 -0.788609 -482 120.73)" stroke="black" strokeWidth="2"/>
  </svg>
);

export default CardsBg2;


