const RisePoint = (props) => (
  <svg width="56" height="83" viewBox="0 0 56 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1.99999" height="42" transform="matrix(-1 -3.46229e-07 -1.98668e-07 1 29 0.708984)" fill="url(#paint0_linear_3316_125327)"/>
    <g filter="url(#filter0_d_3316_125327)">
      <circle cx="28" cy="50.709" r="8" fill="url(#paint1_linear_3316_125327)"/>
      <circle cx="28" cy="50.709" r="7.5" stroke="url(#paint2_linear_3316_125327)"/>
    </g>
    <g filter="url(#filter1_d_3316_125327)">
      <circle cx="27.9999" cy="50.7091" r="2.66667" fill="#1ECACA"/>
    </g>
    <defs>
      <filter id="filter0_d_3316_125327" x="0" y="26.709" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3316_125327"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3316_125327" result="shape"/>
      </filter>
      <filter id="filter1_d_3316_125327" x="5.33325" y="32.0425" width="45.3333" height="45.3335" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3316_125327"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3316_125327" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_3316_125327" x1="0.999996" y1="0" x2="0.999996" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#05001E"/>
        <stop offset="1" stopColor="#24F2F2"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3316_125327" x1="20.6667" y1="50.3757" x2="35.6667" y2="50.3757" gradientUnits="userSpaceOnUse">
        <stop stopColor="#36F8F8"/>
        <stop offset="1" stopColor="#39F8F8"/>
      </linearGradient>
      <linearGradient id="paint2_linear_3316_125327" x1="28" y1="42.709" x2="28" y2="64.0423" gradientUnits="userSpaceOnUse">
        <stop offset="0.326305" stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>


);

export default RisePoint;


