// utils
import { $api } from '../utils/api-client';

export function checkCustomLink(link) {
  return $api.get(`link/check?search=${link}`);
}

export function addCustomLink(payload) {
  return $api.post(`link`, payload);
}

