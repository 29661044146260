// core
import {useTranslation} from "react-i18next";

// styles
import './index.scss'

const Footer = () => {
  const {t} = useTranslation();

  const currentYear =  new Date().getFullYear()
  return (
    <footer className='footer'>
      <div className='footer__bg'>
        <svg className='footer__bg-1' width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.7602 1.90778C24.4865 0.675621 26.1321 0.235624 27.4356 0.925193C28.7392 1.61476 29.2071 3.17281 28.4809 4.40497L25.1932 9.98293L25.2085 9.99101C22.2182 15.7474 24.493 22.7833 30.487 25.9541L35.2077 28.4513C36.5113 29.1408 36.9792 30.6989 36.253 31.9311C35.5267 33.1632 33.8811 33.6032 32.5776 32.9136L15.4652 23.8613C15.2614 23.7535 15.0781 23.6244 14.9165 23.4789C14.701 23.4249 14.4888 23.3448 14.285 23.237C12.9815 22.5475 12.5135 20.9894 13.2397 19.7573L23.7602 1.90778Z" fill="#F88AAF"/>
          <path d="M28.4809 4.40497C29.2071 3.17281 28.7392 1.61476 27.4356 0.925193C26.1321 0.235624 24.4865 0.675621 23.7602 1.90778L20.4726 7.48575L20.4573 7.47767C16.8533 12.9094 9.33727 14.7661 3.34323 11.5953L-1.37744 9.09808C-2.68099 8.40851 -4.32659 8.84851 -5.05283 10.0807C-5.77907 11.3128 -5.31111 12.8709 -4.00756 13.5604L13.1049 22.6128C13.3086 22.7205 13.5208 22.8006 13.7364 22.8546C13.898 23.0001 14.0812 23.1293 14.285 23.237C15.5886 23.9266 17.2342 23.4866 17.9604 22.2545L28.4809 4.40497Z" fill="#F88AAF"/>
          <path d="M2.7191 37.607C1.99286 38.8392 2.46081 40.3972 3.76437 41.0868C5.06793 41.7764 6.71353 41.3364 7.43976 40.1042L10.7274 34.5262L10.7427 34.5343C14.3467 29.1026 21.8627 27.2459 27.8568 30.4167L32.5776 32.9136C33.8811 33.6032 35.5267 33.1632 36.253 31.9311C36.9792 30.6989 36.5113 29.1408 35.2077 28.4513L18.0951 19.3992C17.8914 19.2914 17.6792 19.2114 17.4636 19.1574C17.302 19.0119 17.1188 18.8827 16.915 18.7749C15.6114 18.0854 13.966 18.5251 13.2397 19.7573L2.7191 37.607Z" fill="#F88AAF"/>
          <path d="M7.43976 40.1042C6.71353 41.3364 5.06793 41.7764 3.76437 41.0868C2.46081 40.3972 1.99286 38.8392 2.7191 37.607L6.00675 32.0291L5.99148 32.021C8.98175 26.2646 6.70699 19.2287 0.712947 16.0579L-4.00756 13.5604C-5.31111 12.8709 -5.77907 11.3128 -5.05283 10.0807C-4.32659 8.84851 -2.68099 8.40851 -1.37744 9.09808L15.7348 18.1507C15.9386 18.2584 16.1219 18.3876 16.2835 18.5331C16.499 18.5871 16.7112 18.6672 16.915 18.7749C18.2185 19.4645 18.6866 21.0223 17.9604 22.2545L7.43976 40.1042Z" fill="#F88AAF"/>
        </svg>

        <svg className='footer__bg-2' width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M26.2663 -5.31016C25.0598 -9.45356 20.5499 -11.8407 16.193 -10.6421L6.08765 -7.86185C1.73078 -6.66317 -0.823152 -2.33257 0.383283 1.81082L3.01053 10.8339C4.21697 14.9773 8.72692 17.3645 13.0838 16.1658L23.1892 13.3856C27.546 12.1869 30.1 7.85632 28.8935 3.71292L26.2663 -5.31016ZM20.6278 -2.8582C19.9144 -1.69518 18.3292 -1.26409 17.0872 -1.89535C15.8452 -2.52661 15.4167 -3.98117 16.1301 -5.1442C16.8436 -6.30723 18.4288 -6.73831 19.6708 -6.10705C20.9128 -5.47579 21.3413 -4.02123 20.6278 -2.8582ZM16.8959 3.22537C16.1825 4.38839 14.5973 4.81947 13.3552 4.18821C12.1132 3.55695 11.6848 2.1024 12.3982 0.93937C13.1117 -0.223657 14.6969 -0.65474 15.9389 -0.0234795C17.1809 0.60778 17.6094 2.06234 16.8959 3.22537ZM13.1635 9.30943C12.45 10.4725 10.8648 10.9035 9.62281 10.2723C8.3808 9.64102 7.95232 8.18646 8.66577 7.02343C9.37922 5.86041 10.9644 5.42933 12.2064 6.06059C13.4484 6.69185 13.8769 8.1464 13.1635 9.30943Z" fill="url(#paint0_linear_3049_6733)"/>
          <defs>
            <linearGradient id="paint0_linear_3049_6733" x1="22.2106" y1="-10.0854" x2="28.6588" y2="10.4484" gradientUnits="userSpaceOnUse">
              <stop stopColor="#24F2F2"/>
              <stop offset="1" stopColor="#2D8BFE"/>
            </linearGradient>
          </defs>
        </svg>

        <svg className='footer__bg-3' width="45" height="26" viewBox="0 0 45 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.0024 33.5868C17.4799 39.3816 27.7976 38.9329 34.0474 32.5856C40.2976 26.2378 40.1147 16.3937 33.6374 10.5996C27.1619 4.80633 16.8439 5.25541 10.5944 11.6041C4.34393 17.9505 4.52769 27.794 11.0024 33.5868ZM27.1733 17.3284C28.9772 18.5291 30.1366 19.9234 29.2665 21.7226C28.6319 23.0403 27.6416 23.4989 26.5281 23.4585C27.7342 24.5561 28.1188 25.8546 26.8307 27.5927C25.2316 29.7515 23.1256 29.4163 20.5625 28.2039L19.1084 30.595L17.634 29.7913L19.0688 27.4323C18.6866 27.2243 18.2972 27.0078 17.8977 26.7792L16.457 29.1495L14.9844 28.3463L16.44 25.9503C16.3026 25.8743 16.1646 25.7971 16.0256 25.7193C15.8169 25.6026 15.6061 25.4846 15.3919 25.3676L13.4733 24.3215L14.8128 22.7594C14.8128 22.7594 15.8933 23.3706 15.8844 23.344C16.302 23.5708 16.5906 23.338 16.7286 23.1685L19.0268 19.3884C19.0631 19.4078 19.0985 19.4272 19.1327 19.4459C19.1497 19.4552 19.1663 19.4643 19.1826 19.4732C19.1267 19.4318 19.074 19.4008 19.0329 19.3781L20.673 16.6797C20.7943 16.3563 20.8061 15.9113 20.209 15.5855C20.239 15.5757 19.138 15.0024 19.138 15.0024L20.0736 13.4623L22.1066 14.5717L22.1024 14.5792C22.4081 14.7456 22.7251 14.9107 23.0482 15.0784L24.4881 12.7107L25.9616 13.5142L24.5503 15.8356C24.9485 16.0425 25.3493 16.251 25.7369 16.4623L27.1387 14.156L28.6131 14.9597L27.1733 17.3284ZM19.2786 25.2144C20.4759 25.889 23.0925 27.3633 24.0901 25.7204C25.1112 24.0409 22.5406 22.7172 21.2863 22.0714C21.1461 21.9992 21.0224 21.9355 20.9219 21.8807L18.9922 25.0546C19.0747 25.0995 19.1709 25.1537 19.2786 25.2144ZM22.0459 20.5628C23.0432 21.1281 25.2185 22.3612 26.1265 20.8684C27.0546 19.3412 24.9089 18.2441 23.861 17.7084C23.7431 17.6481 23.6392 17.5949 23.555 17.5491L21.805 20.4278C21.8744 20.4656 21.9553 20.5114 22.0459 20.5628Z" fill="#18F3FF"/>
        </svg>

        <svg className='footer__bg-4' width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.3655 6.06225L26.7577 6.63135L15.0941 26.2834L15.1819 26.5904L27.8074 26.1496L27.3655 6.06225Z" fill="#F2AD7D"/>
          <path d="M27.2155 5.95084L8.80975 16.1346L15.0337 26.4771L20.694 16.9395L27.2155 5.95084Z" fill="#F2AD7D"/>
          <path d="M14.2406 28.2367L14.0427 28.3128L9.87437 35.3299L9.79545 35.7198L26.911 27.8005L14.2406 28.2367Z" fill="#F2AD7D"/>
          <path d="M9.6774 35.618L14.1221 28.1355L7.87598 17.771L9.6774 35.618Z" fill="#F2AD7D"/>
          <path d="M15.1847 26.5698L27.8756 26.1272L20.8752 16.9922L15.1847 26.5698Z" fill="#F2AD7D"/>
          <path d="M8.82712 16.0911L15.0843 26.477L20.7748 16.8994L8.82712 16.0911Z" fill="#F2AD7D"/>
        </svg>
      </div>

      <div className='app__wrapper'>
        <div className='footer__grid'>
          <div className='footer__right'>
            <span>© {currentYear} {t('footer.copy.title')}</span> <span>{t('footer.copy.text')}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
