// core
import {useTranslation} from "react-i18next";

// components
import CasinoBanner from './../../../assets/images/CasinoBanner.png'
import ListPoint from "../../../components/icons/ListPoint";

// styles
import './index.scss'


const HomeCasino= () => {
  const {t} = useTranslation();
  return (
    <div className="home-casino">
      <div className="home-casino__grid">
        <div className="home-casino__content">
          <h2 className="home-casino__title"
               dangerouslySetInnerHTML={{__html: t('modules.home.casino.title')}}/>
          <ul className="home-casino__list">
            <li><ListPoint/>{t('modules.home.casino.list.item1')}</li>
            <li><ListPoint/>{t('modules.home.casino.list.item2')}</li>
            <li><ListPoint/>{t('modules.home.casino.list.item3')}</li>
            <li><ListPoint/>{t('modules.home.casino.list.item4')}</li>
          </ul>
        </div>
        <div className="home-casino__image">
          <img src={CasinoBanner} alt=''/>
        </div>
      </div>
    </div>
  );
};

export default HomeCasino;
