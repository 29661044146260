const Tusd = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2755_121786)">
      <path d="M16 32C7.2 32 0 24.8 0 16C0 7.2 7.2 0 16 0C24.8 0 32 7.2 32 16C32 24.8 24.8 32 16 32Z" fill="#002868"/>
      <path d="M24.0639 25.6H7.9359C7.0911 25.6 6.3999 24.9088 6.3999 24.064V7.93599C6.3999 7.09119 7.0911 6.39999 7.9359 6.39999H24.0639C24.9087 6.39999 25.5999 7.09119 25.5999 7.93599V24.064C25.5999 24.9088 24.9087 25.6 24.0639 25.6Z" fill="white"/>
      <path d="M10.1465 23.6672C10.0959 23.6672 10.0549 23.6211 10.0549 23.5706V20.9907C10.0549 20.9402 10.014 20.8941 9.96341 20.8941H9.05269C9.00149 20.8941 8.95605 20.8531 8.95605 20.8026V20.304C8.95605 20.2534 9.00213 20.2074 9.05269 20.2074H11.8559C11.9065 20.2074 11.9525 20.2528 11.9525 20.304V20.8026C11.9525 20.8531 11.9065 20.8941 11.8559 20.8941H10.9503C10.8997 20.8941 10.8537 20.9395 10.8537 20.9907V23.5706C10.8537 23.6211 10.8127 23.6672 10.7621 23.6672H10.1465Z" fill="#002868"/>
      <path d="M14.7507 20.304C14.7507 20.2534 14.7916 20.2074 14.8422 20.2074H15.4528C15.5033 20.2074 15.5494 20.2528 15.5494 20.304V22.0646C15.5494 23.1078 14.9593 23.7133 14.023 23.7133C13.0918 23.7133 12.5068 23.1078 12.5068 22.0646V20.304C12.5068 20.2534 12.5478 20.2074 12.5984 20.2074H13.2089C13.2595 20.2074 13.3056 20.2528 13.3056 20.304V22.0896C13.3056 22.6035 13.5699 22.9696 14.0281 22.9696C14.4864 22.9696 14.7507 22.6035 14.7507 22.0896V20.304Z" fill="#002868"/>
      <path d="M18.3532 21.337C18.272 21.337 18.2464 21.3063 18.2156 21.2455C18.1088 20.9959 17.8745 20.8384 17.5187 20.8384C17.198 20.8384 16.9286 20.9504 16.9286 21.1642C16.9286 21.3984 17.1782 21.4951 17.7273 21.561C18.5619 21.6576 19.04 21.9123 19.04 22.5735C19.04 23.3165 18.3884 23.7082 17.5545 23.7082C16.832 23.7082 16.2112 23.3568 16.1145 22.6195C16.1043 22.5485 16.1401 22.5229 16.2112 22.5229H16.7712C16.8422 22.5229 16.8832 22.5536 16.9036 22.6093C16.9798 22.8538 17.2396 23.0419 17.616 23.0419C17.9468 23.0419 18.2368 22.9197 18.2368 22.6605C18.2368 22.3501 17.9315 22.2944 17.3209 22.2176C16.6188 22.1261 16.1305 21.9226 16.1305 21.2762C16.1305 20.6298 16.6854 20.1671 17.5552 20.1671C18.3385 20.1671 18.8832 20.5895 18.9952 21.2205C19.0105 21.2915 18.99 21.3376 18.9139 21.3376H18.3532V21.337Z" fill="#002868"/>
      <path d="M19.7317 23.6672C19.6811 23.6672 19.6401 23.6211 19.6401 23.5706V20.304C19.6401 20.2534 19.6811 20.2074 19.7317 20.2074H21.1153C22.1432 20.2074 22.9419 20.9146 22.9419 21.9475C22.9419 22.9651 22.1534 23.6672 21.1153 23.6672H19.7317ZM21.1153 22.9798C21.6702 22.9798 22.1022 22.5421 22.1022 21.9366C22.1022 21.3312 21.6696 20.8934 21.1102 20.8934H20.5355C20.4849 20.8934 20.444 20.9389 20.444 20.9901V22.8877C20.444 22.9382 20.4849 22.9792 20.5355 22.9792H21.1153V22.9798Z" fill="#002868"/>
      <path d="M25.088 18.304H6.91201C6.77121 18.304 6.65601 18.1888 6.65601 18.048V7.93601C6.65601 7.23201 7.23201 6.65601 7.93601 6.65601H24.064C24.768 6.65601 25.344 7.23201 25.344 7.93601V18.048C25.344 18.1888 25.2288 18.304 25.088 18.304Z" fill="#002868"/>
      <path d="M16.9427 16.2278H15.2787C15.1731 16.2278 15.0867 16.1414 15.0867 16.0358V8.99583C15.0867 8.89023 15.1731 8.80383 15.2787 8.80383H16.9427C17.0483 8.80383 17.1347 8.89023 17.1347 8.99583V16.0358C17.1347 16.1414 17.0483 16.2278 16.9427 16.2278Z" fill="white"/>
      <path d="M19.776 10.8518H12.224C12.1184 10.8518 12.032 10.7654 12.032 10.6598V8.99583C12.032 8.89023 12.1184 8.80383 12.224 8.80383H19.776C19.8816 8.80383 19.968 8.89023 19.968 8.99583V10.6598C19.968 10.7654 19.8816 10.8518 19.776 10.8518Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_2755_121786">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default Tusd;
