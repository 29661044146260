const FeatureGradient4 = (props) => (
  <svg width="119" height="51" viewBox="0 0 119 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" filter="url(#filter0_f_2845_122452)">
      <ellipse cx="59.5" cy="25.5" rx="44" ry="10.5" fill="url(#paint0_linear_2845_122452)"/>
    </g>
    <defs>
      <filter id="filter0_f_2845_122452" x="0.5" y="0" width="118" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_2845_122452"/>
      </filter>
      <linearGradient id="paint0_linear_2845_122452" x1="93" y1="36" x2="30" y2="17.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F7BCA7"/>
        <stop offset="1" stopColor="#24F2F2"/>
      </linearGradient>
    </defs>
  </svg>

);

export default FeatureGradient4;


