// core
import {useTranslation} from "react-i18next";

// components
import GeneralBGHow from "../../../components/images/GeneralBGHow";
import How1 from "../../../components/images/home/How1";
import How2 from "../../../components/images/home/How2";
import How3 from "../../../components/images/home/How3";
import How4 from "../../../components/images/home/How4";
import MediumBtn from "../../../components/icons/MediumBtn";
import AppButton from "../../../components/atoms/Button";

// styles
import './index.scss'


const HomeHow = () => {
  const {t} = useTranslation();
  return (
    <div className="home-how">
      <div className="home-how__bg">
        <GeneralBGHow/>
      </div>
      <div className="home-how__content">
        <h2 className="home-how__title">
          {t('modules.home.how.title')}
        </h2>
        <div className="home-how__sub-title">
          {t('modules.home.how.text')}
        </div>
        <div className="home-how__grid">
          <div className="home-how__grid-item">
            <div className="home-how__card">
              <div className="home-how__card-image">
                <How1/>
              </div>
              <div className="home-how__card-text">
                {t('modules.home.how.list.item1')}
              </div>
            </div>
          </div>

          <div className="home-how__grid-item">
            <div className="home-how__card">
              <div className="home-how__image">
                <How2/>
              </div>
              <div className="home-how__card-text">
                {t('modules.home.how.list.item2')}
              </div>
            </div>
          </div>

          <div className="home-how__grid-item">
            <div className="home-how__card">
              <div className="home-how__image">
                <How3/>
              </div>
              <div className="home-how__card-text">
                {t('modules.home.how.list.item3')}
              </div>
            </div>
          </div>

          <div className="home-how__grid-item">
            <div className="home-how__card">
              <div className="home-how__image">
                <How4/>
              </div>
              <div className="home-how__card-text">
                {t('modules.home.how.list.item4')}
              </div>
            </div>
          </div>
        </div>
        <div className="home-how__actions">
          <div className='home-how__btns'>
            <AppButton href={'https://medium.com/@moonwinadv/moonwin-game-how-it-works-f98703920820'}
                       outline
                       append={<MediumBtn/>}>
              {t('actions.readMore')}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHow;
