// core
import React, {useEffect, useState} from 'react';
import {useCopyToClipboard, useWindowSize} from 'react-use';
import {useTranslation} from "react-i18next";

// service
import {getGamesData} from "../../../services/games";

// components
import PolygonGame from "../../../components/icons/crypto/PolygonGame";
import UsdtGame from "../../../components/icons/crypto/UsdtGame";


// styles
import './index.scss'

// data
import {REQUEST_DATA} from './data'


const HomeGames = () => {
  const {t} = useTranslation();
  const {width} = useWindowSize();
  const [, copyToClipboard] = useCopyToClipboard()
  const [activeTab, setActiveTab] = useState(1)
  const [, setIsGameDataLoading] = useState(false)
  const [gameData, setGameData] = useState([])

  const getTableData = () => {
    setIsGameDataLoading(true);
    getGamesData(REQUEST_DATA)
      .then(res => {
        let list = res.data.result.length > 10 ? res.data.result.slice(0, 10) : res.data.result
        setGameData(list)
      }).catch(res => console.error('error', res))
      .finally(() => setIsGameDataLoading(false));
  };

  useEffect(() => {
    getTableData();
  }, [])


  const onTabHandler = (id) => {
    setActiveTab(id);
  }

  const getFormmatId = (longId) => {
    return width > 768 ? longId : `${longId.substring(0, 3)}...${longId.substring(longId.length - 3)}`
  }

  const getFormmatBet = (value, powExponent = 18, fractionDigit = 9) => {
    return Number((value / Math.pow(10, powExponent)).toFixed(fractionDigit))
  }

  const getFormmatNumber = (value, fractionDigit = 2) => {
    return Number((value / Math.pow(10, 2)).toFixed(fractionDigit))
  }

  const getExponent = (value) => {
    let countDigit = value.length
    return countDigit <= 7 ? 6 : 18
  }


  return (
    <div className="home-games">
      <h2 className="home-games__title">
        {t('modules.home.games.title')}
      </h2>

      <div className="home-games__tabs">
        <div className="home-games__tabs-btns">
          {[1].map(item => (
            <button
              key={item}
              onClick={() => onTabHandler(item)}
              className={
                'home-games__tabs-btn' +
                (activeTab === item ? ' home-games__tabs-btn--active' : '')
              }>
              {t(`modules.home.games.tabs.label${item}`)}

            </button>
          ))}
        </div>
        <div className="home-games__tabs-body">
          {activeTab === 1 && (
            <div className="home-games__tabs-body-item">
              {gameData && gameData.length > 0 && (
                <table>
                  <thead>
                  <tr>
                    <th>{t('modules.home.games.table.label1')}</th>
                    <th>{t('modules.home.games.table.label2')}</th>
                    <th>{t('modules.home.games.table.label3')}</th>
                    <th>{width > 768 ? t('modules.home.games.table.label4D') : t('modules.home.games.table.label4M')}</th>
                    <th>{width > 768 ? t('modules.home.games.table.label5D') : t('modules.home.games.table.label5M')}</th>
                    <th>{width > 768 ? t('modules.home.games.table.label6D') : t('modules.home.games.table.label6M')}</th>
                    <th>{t('modules.home.games.table.label7')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {gameData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`https://polygonscan.com/tx/${item.transaction_hash}`} target="_blank" rel="noreferrer"
                           className="home-games__link">
                          {getFormmatId(item.transaction_hash)}
                        </a>
                      </td>
                      <td className='home-games__tabs-copy-wrapper'>
                        <button className='home-games__tabs-copy' onClick={() => copyToClipboard(item.data.requestId)}>
                          <span className='home-games__tabs-tooltip'>Copy</span>
                          {getFormmatId(item.data.requestId)}
                        </button>

                      </td>
                      <td>
                        <div className="home-games__bet">
                          {getFormmatBet(item.data.userGuess[4], getExponent(item.data.userGuess[4]))}
                          {width > 768 && (
                            <>
                              {getExponent(item.data.userGuess[4]) === 6 ? <UsdtGame/> : <PolygonGame/>}
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        {getFormmatBet(item.data.userGuess[5], 18, 2)}
                      </td>
                      <td>
                        {getFormmatNumber(item.data.winningNumber)}
                      </td>
                      <td>
                        <div className={
                          'home-games__status' +
                          (item.data.didUserWin ? ' home-games__status--win' : ' home-games__status--lose')
                        }>
                          {width > 768 ? (
                            <>{item.data.didUserWin ? 'Win' : 'Lose'}</>
                          ) : (
                            <>{item.data.didUserWin ? 'W' : 'L'}</>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={
                          'home-games__payout' +
                          (item.data.didUserWin ? ' home-games__payout--active' : '')
                        }>
                          {item.data.didUserWin ? getFormmatBet(item.data.userGuess[3], getExponent(item.data.userGuess[4]), 9) : 0}
                          {width > 768 && (
                            <>
                              {getExponent(item.data.userGuess[4]) === 6 ? <UsdtGame/> : <PolygonGame/>}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              )}

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeGames;
