// core
import {Link as RouterLink} from 'react-router-dom';

// components

// styles
import './index.scss'

const AppButton = ({
                     type,
                     children,
                     onClick,
                     className,
                     outline,
                     disabled,
                     size,
                     append,
                     href,
                     target,
                     to,
                     loading
                   }) => {
  return to ? (
    <RouterLink to={to}
                disabled={!!disabled}
                className={
                  'app-button' +
                  (outline ? ' app-button--outline' : ' app-button--fill') +
                  (size && size === 'small' ? ' app-button--small' : '') +
                  (size && size === 'big' ? ' app-button--big' : '') +
                  (className ? ` ${className}` : '')
                }
    >
      <div className="app-button__content">
        {loading ? (
          <>
            <span className="app-button__loader"/>
          </>
        ) : (
          <>
            {children}
            {append && (<div className="app-button-append">{append}</div>)}
          </>)
        }
      </div>
    </RouterLink>
  ) : href ? (
    <a href={href}
       disabled={!!disabled}
       target={target ? target : '_self'}
       className={
         'app-button' +
         (outline ? ' app-button--outline' : ' app-button--fill') +
         (size && size === 'small' ? ' app-button--small' : '') +
         (size && size === 'big' ? ' app-button--big' : '') +
         (className ? ` ${className}` : '')
       }
    >
      <div className="app-button__content">
        {loading ? (
          <>
            <span className="app-button__loader"/>
          </>
        ) : (
          <>
            {children}
            {append && (<div className="app-button-append">{append}</div>)}
          </>)
        }
      </div>
    </a>
  ) : (
    <button type={type ? type : 'button'}
            onClick={onClick}
            disabled={!!disabled}
            className={
              'app-button' +
              (outline ? ' app-button--outline' : ' app-button--fill') +
              (size && size === 'small' ? ' app-button--small' : '') +
              (size && size === 'big' ? ' app-button--big' : '') +
              (className ? ` ${className}` : '')
            }
    >
      <div className="app-button__content">
        {loading ? (
          <>
            <span className="app-button__loader"/>
          </>
        ) : (
          <>
            {children}
            {append && (<div className="app-button-append">{append}</div>)}
          </>)
        }
      </div>
    </button>
  );
};

export default AppButton;
