const UsdtGame = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#26A17B"/>
    <path d="M18.0509 17.475V17.4729C17.9335 17.4814 17.3287 17.5177 15.9794 17.5177C14.9021 17.5177 14.1437 17.4857 13.877 17.4729V17.4761C9.72979 17.2937 6.63432 16.5716 6.63432 15.7076C6.63432 14.8446 9.72979 14.1225 13.877 13.9369V16.7572C14.1479 16.7764 14.9245 16.8222 15.9975 16.8222C17.285 16.8222 17.9303 16.7689 18.0509 16.7582V13.939C22.1895 14.1236 25.2775 14.8457 25.2775 15.7076C25.2775 16.5716 22.1895 17.2916 18.0509 17.475ZM18.0509 13.6457V11.122H23.8258V7.27344H8.10205V11.122H13.877V13.6446C9.18365 13.8601 5.65405 14.7902 5.65405 15.9038C5.65405 17.0174 9.18365 17.9465 13.877 18.163V26.2505H18.0509V18.1609C22.7367 17.9454 26.2578 17.0164 26.2578 15.9038C26.2578 14.7913 22.7367 13.8622 18.0509 13.6457Z" fill="white"/>
  </svg>


);

export default UsdtGame;
