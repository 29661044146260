const CirclePlus = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24Z"
          fill="#353535"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M24 14C25.1046 14 26 14.8954 26 16V22H32C33.1046 22 34 22.8954 34 24C34 25.1046 33.1046 26 32 26H26V32C26 33.1046 25.1046 34 24 34C22.8954 34 22 33.1046 22 32V26H16C14.8954 26 14 25.1046 14 24C14 22.8954 14.8954 22 16 22H22V16C22 14.8954 22.8954 14 24 14Z"
          fill="white" fillOpacity="0.5"/>
  </svg>
);

export default CirclePlus;


