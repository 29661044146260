const REQUEST_DATA = {
  chain: 'polygon',
  topic: '0x71672a970202d6a968e03ffeb4f388025b2524f78c8d8bab2f1f4961b58352c6',
  address: '0x153DeC40755788591d30891050d0c39Ed5862C10',
  abi: {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "requestId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "betIndex",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address payable",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "influencer",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "winningNumber",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address payable",
            "name": "user",
            "type": "address"
          },
          { "internalType": "uint16", "name": "lowerNumber", "type": "uint16" },
          { "internalType": "uint16", "name": "upperNumber", "type": "uint16" },
          {
            "internalType": "uint256",
            "name": "prizeAmount",
            "type": "uint256"
          },
          { "internalType": "uint256", "name": "betAmount", "type": "uint256" },
          {
            "internalType": "uint256",
            "name": "multiplier",
            "type": "uint256"
          },
          {
            "internalType": "contract IERC20Upgradeable",
            "name": "token",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct RandomNumber.UserGuess",
        "name": "userGuess",
        "type": "tuple"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "didUserWin",
        "type": "bool"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "success",
        "type": "bool"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "isPrize",
        "type": "bool"
      }
    ],
    "name": "BetResult",
    "type": "event"
  }
}


export {REQUEST_DATA}
