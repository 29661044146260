// core
import {useTranslation} from "react-i18next";

// components
import YouGet from './../../../assets/images/you-get.png'

// styles
import './index.scss'
import AppButton from "../../../components/atoms/Button";
import React from "react";

const InfluencerGet = ({onClick}) => {
  const {t} = useTranslation();
  return (
    <div className="influencer-get">
      <div className="influencer-get__grid">
        <div className="influencer-get__image">
          <img src={YouGet} alt=''/>
        </div>
        <div className="influencer-get__content">
          <h2 className="influencer-get__title"
              dangerouslySetInnerHTML={{__html: t('modules.influencer.get.title')}}/>
        </div>
      </div>
      <div className="influencer-get__list">
        <div className="influencer-get__card">
          <div className='influencer-get__card-text'>
            {t('modules.influencer.get.list.item1')}
          </div>
        </div>
        <div className="influencer-get__card">
          <div className='influencer-get__card-text'>
            {t('modules.influencer.get.list.item2')}
          </div>
        </div>
        <div className="influencer-get__card">
          <div className='influencer-get__card-text'>
            {t('modules.influencer.get.list.item3')}
          </div>
        </div>
        <div className="influencer-get__card">
          <div className='influencer-get__card-text'>
            {t('modules.influencer.get.list.item4')}
          </div>
        </div>
        <div className="influencer-get__card">
          <div className='influencer-get__card-text'>
            {t('modules.influencer.get.list.item5')}
          </div>
        </div>
      </div>
      <div className="influencer-get__actions">
        <AppButton
          onClick={onClick}
          className="influencer-get__btn app-button--fill-2">
          {t('actions.becomePartner')}
        </AppButton>
      </div>
    </div>
  );
};

export default InfluencerGet;
