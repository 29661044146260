const CardsBg1 = (props) => (
  <svg width="1520" height="1369" viewBox="0 0 1520 1369" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_2860_122898)">
      <path d="M739.872 400.941C395.869 406.431 120.206 611.759 124.161 859.553C128.115 1107.35 460.043 854.193 804.045 848.703C1148.05 843.213 1373.86 1087.47 1369.9 839.672C1365.95 591.877 1083.87 395.451 739.872 400.941Z" fill="#F2AD7D" fillOpacity="0.2"/>
    </g>
    <defs>
      <filter id="filter0_f_2860_122898" x="-275.881" y="0.828278" width="2045.84" height="1367.29" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_2860_122898"/>
      </filter>
    </defs>
  </svg>

);

export default CardsBg1;


