const HeadBannerBG2 = (props) => (
  <svg width="638" height="613" viewBox="0 0 638 613" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4" filter="url(#filter0_f_2775_117764)">
      <path
        d="M220.033 421.938C142.143 359.87 127.856 248.244 188.123 172.614C248.389 96.983 264.175 233.216 342.065 295.284C419.956 357.352 530.456 321.75 470.189 397.381C409.923 473.011 297.924 484.006 220.033 421.938Z"
        fill="url(#paint0_linear_2775_117764)"/>
    </g>
    <defs>
      <filter id="filter0_f_2775_117764" x="0.827148" y="0.344299" width="636.657" height="611.81"
              filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2775_117764"/>
      </filter>
      <linearGradient id="paint0_linear_2775_117764" x1="120.013" y1="309.152" x2="290.552" y2="164.94"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFC399"/>
        <stop offset="1" stopColor="#C48BFF"/>
      </linearGradient>
    </defs>
  </svg>
);

export default HeadBannerBG2;


