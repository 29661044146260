// core
import {useState} from 'react';
import {useTranslation} from "react-i18next";

// components
import Expander from '../../../components/atoms/Expander'

// styles
import './index.scss'


const HomeFaq = () => {
  const {t} = useTranslation();
  const [expanderId, setExpanderId] = useState(null);

  const onClickHandler = (id) => {
    setExpanderId(expanderId !== id ? id : null);
  }

  return (
    <div className="home-faq">
      <div className="home-faq__title"><span>{t('modules.home.faq.title')}</span></div>
      <div className='home-faq__expander'>
        {[1,2,3,4,5,6,7,8].map(item=>(
          <div className='home-faq__expander-item' key={item} id={`item-${item}`}>
            <Expander title={`${item}. ${t('modules.home.faq.list.item'+item+'.title')}`}
                      active={expanderId === item}
                      onClick={() => onClickHandler(item)}>
              <div className='home-faq__expander-content'
                   dangerouslySetInnerHTML={{__html: t(`modules.home.faq.list.item${item}.text`)}}/>
            </Expander>
          </div>
        ))}
      </div>

    </div>
  );
};

export default HomeFaq;
