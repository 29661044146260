const Polygon = (props) => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="16.5" fill="url(#paint0_radial_2742_120158)"/>
    <g filter="url(#filter0_f_2561_112836)">
      <circle cx="24.5" cy="24.5" r="20" stroke="#CCAEFF"/>
    </g>
    <circle cx="24.5" cy="24.5" r="20" stroke="#8247E5"/>
    <path d="M29.7717 20.5447C29.386 20.3145 28.8847 20.3145 28.4607 20.5447L25.4528 22.3098L23.4092 23.4608L20.4014 25.226C20.0157 25.4561 19.5144 25.4561 19.0903 25.226L16.6994 23.8445C16.3137 23.6144 16.0436 23.1922 16.0436 22.7316V20.007C16.0436 19.5464 16.2749 19.1247 16.6994 18.8941L19.0515 17.5511C19.4371 17.321 19.9385 17.321 20.3625 17.5511L22.7146 18.8941C23.1003 19.1242 23.37 19.5464 23.37 20.007V21.7722L25.4136 20.5827V18.818C25.4136 18.3573 25.1823 17.9356 24.7583 17.7051L20.4014 15.1726C20.0157 14.9425 19.5144 14.9425 19.0903 15.1726L14.6557 17.7051C14.2312 17.9352 14 18.3573 14 18.818V23.9215C14 24.3817 14.2312 24.8038 14.6557 25.0344L19.0903 27.5668C19.476 27.797 19.9773 27.797 20.4014 27.5668L23.4092 25.8402L25.4528 24.6507L28.4607 22.924C28.8463 22.6939 29.3476 22.6939 29.7717 22.924L32.1238 24.267C32.5095 24.4972 32.7792 24.9193 32.7792 25.3799V28.1041C32.7792 28.5647 32.5479 28.9864 32.1238 29.217L29.7717 30.5985C29.386 30.8286 28.8847 30.8286 28.4607 30.5985L26.1086 29.2555C25.7229 29.0253 25.4528 28.6032 25.4528 28.1425V26.3774L23.4092 27.5668V29.332C23.4092 29.7922 23.6404 30.2144 24.0645 30.4449L28.4991 32.9774C28.8847 33.2075 29.386 33.2075 29.8101 32.9774L34.2447 30.4449C34.6304 30.2148 34.9 29.7927 34.9 29.332V24.2281C34.9 23.7679 34.6688 23.3458 34.2447 23.1156L29.7717 20.5447Z" fill="white"/>
    <defs>
      <filter id="filter0_f_2561_112836" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2561_112836"/>
      </filter>
      <radialGradient id="paint0_radial_2742_120158" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 24.5) rotate(90) scale(25)">
        <stop stopColor="#05001C"/>
        <stop offset="1" stopColor="#8247E5"/>
      </radialGradient>
    </defs>
  </svg>

);

export default Polygon;
